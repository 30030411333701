/* eslint-disable */
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { LoadingService } from '@cores/services/loading.service';
import { PaginatorModel } from '@cores/models/paginator.model';
import { ActionOption, Column, DataTable } from '@cores/models/data-table.model';
import { MenuItem } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { Action, ColumnDataType } from '@cores/utils/enums';
import { Table } from 'primeng/table';

enum SelectionMode {
  Single = 'single',
  Checkbox = 'checkbox',
}

@Component({
  selector: 'lp-table',
  templateUrl: './lp-table.component.html',
  styleUrls: ['./lp-table.component.scss'],
})
export class LPTableComponent implements OnChanges, OnInit {
  @Input() columns: Column[] = [];
  @Input() actions: ActionOption[] = [];
  @Input() paginator = true;
  @Input() showHeader = true;
  @Input() selectionMode = '';
  @Input() selectedNode: any;
  @Input() frozenValue: any[] = [];
  @Output('selectedNodeChange') selectedNodeChange = new EventEmitter();
  @Input() treeTable = false;
  @Input() dataTable?: DataTable;
  @Output() onPageChange: EventEmitter<PaginatorModel> = new EventEmitter<PaginatorModel>();
  @Output() onDbClickRow: EventEmitter<any> = new EventEmitter<any>();
  @Output() onClickRow: EventEmitter<any> = new EventEmitter<any>();
  @Output() onAction: EventEmitter<any> = new EventEmitter<any>();
  items: MenuItem[] = [];
  itemFocus: any;
  columnDataType = ColumnDataType;
  offset = 0;
  @ViewChild('table') table: Table | undefined;

  SelectionMode = SelectionMode;

  selectedNodeArray: any[] = [];

  constructor(public loadingService: LoadingService, private translate: TranslateService) {
    this.translate.onLangChange.subscribe(() => {
      this.items.forEach((item, i) => {
        item.label = this.translate.instant(this.actions[i].label);
      });
    });
  }

  ngOnInit() {
    if (this.selectionMode === SelectionMode.Checkbox) {
      this.selectedNodeArray = this.selectedNode;
    }
  }

  onRowSelect(event: any) {
    if (this.selectionMode == SelectionMode.Single) {
      this.selectedNodeChange.emit(event?.data);
    }
  }

  onRadioSelect() {
    this.selectedNodeChange.emit(this.selectedNode);
  }

  onCheckboxSelect() {
    this.selectedNode = this.selectedNodeArray;
    this.selectedNodeChange.emit(this.selectedNode);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.offset = this.dataTable?.first || 0;
    this.items = this.actions.map((item, i) => {
      return {
        label: this.translate.instant(this.actions[i].label),
        icon: item.icon,
        styleClass: item.class,
        command: () => {
          this.doActionOption(item.type);
        },
      };
    });
  }

  getActionsItem(invisibleActions?: Action[]) {
    if (invisibleActions && invisibleActions.length > 0) {
      return this.actions.map((item, i) => {
        return {
          label: this.translate.instant(this.actions[i].label),
          icon: item.icon,
          visible: !invisibleActions?.includes(<Action>item.type),
          styleClass: item.class,
          command: () => {
            this.doActionOption(item.type);
          },
        };
      });
    }
    return this.items;
  }

  pageChange(paginator: PaginatorModel) {
    this.onPageChange.emit(paginator);
  }

  doClickRow(event: any, row: any) {
    if (this.selectionMode == SelectionMode.Checkbox) {
      this.table?.toggleRowWithCheckbox(event, row);
      this.selectedNode = this.selectedNodeArray;
      this.selectedNodeChange.emit(this.selectedNode);
    }
    this.itemFocus = row;
    if (event.target.classList.contains('action-col') || event.target.classList.contains('action-cell')) {
      return;
    }
    this.onClickRow.emit(row);
  }

  doDblClickRow(event: any, row: any) {
    this.itemFocus = row;
    if (event.target.classList.contains('action-col') || event.target.classList.contains('action-cell')) {
      return;
    }
    this.onDbClickRow.emit(row);
  }

  doActionOption(type: string) {
    this.onAction.emit({ type, item: this.itemFocus });
  }

  trackByHeader(index: number, item: Column) {
    return item;
  }

  trackByBody(index: number, item: Column) {
    return item;
  }
}
