import { AfterViewInit, Directive, ElementRef, HostListener, Input } from '@angular/core';
import { Calendar } from 'primeng/calendar';

interface cursorLastPosition {
  selectionStart: number;
  selectionEnd: number;
  code: 'Backspace' | 'Delete' | null;
  isDeleteNumber: boolean;
}

@Directive({
  selector: '[appCalendarWithMask]',
})
export class CalendarWithMaskDirective implements AfterViewInit {
  @Input() dateFormat!: 'dd/mm/yy' | 'mm/yy';

  constructor(private el: ElementRef, private calendar: Calendar) {}

  cursorLastPosition: cursorLastPosition | null = null;

  @HostListener('keydown.backspace', ['$event'])
  keydownBackspace(event: KeyboardEvent) {
    this.setSelectionWhenDelete(event);
  }

  @HostListener('keydown.delete', ['$event'])
  keydownDelete(event: KeyboardEvent) {
    this.setSelectionWhenDelete(event);
  }

  ngAfterViewInit(): void {
    this.calendar.onInput.subscribe((event: InputEvent) => {
      const actualValue = this.getValue();
      if (this.dateFormat === 'dd/mm/yy') {
        if (event.data === '/' && [3, 6].includes(actualValue.length)) {
          return;
        }

        // if point is added, for day
        if (
          event.data === '/' &&
          actualValue.length === 2 &&
          actualValue.substring(0, actualValue.length - 1).lastIndexOf('0') === -1
        ) {
          this.treatPointForDay(actualValue);
          return;
        }

        // if point is added, for month
        if (
          event.data === '/' &&
          actualValue.length === 5 &&
          actualValue.substring(0, actualValue.length - 1).lastIndexOf('0') === -1
        ) {
          this.treatPointForMonth(actualValue);
          return;
        }
      } else if (this.dateFormat === 'mm/yy') {
        if (event.data === '/' && actualValue.length === 3) {
          return;
        }

        // if point is added, for day
        if (
          event.data === '/' &&
          actualValue.length === 2 &&
          actualValue.substring(0, actualValue.length - 1).lastIndexOf('0') === -1
        ) {
          this.treatPointForMonth(actualValue);
          return;
        }
      }
      if (this.cursorLastPosition) {
        this.setValueToCalendar(actualValue);
        return;
      }

      // nếu ký tự nhập vào là số hoặc '/' => set cursorLastPosition
      const reg = /^\d+$/;
      if (event.inputType === 'insertText' && (reg.test(event.data!) || event.data === '/')) {
        let selectionEnd = (event.target as any).selectionStart;
        if ((selectionEnd === 3 || (this.dateFormat === 'dd/mm/yy' && selectionEnd === 6)) && reg.test(event.data!)) {
          selectionEnd += 1;
        }
        if ((selectionEnd === 3 || (this.dateFormat === 'dd/mm/yy' && selectionEnd === 6)) && event.data === '/') {
          selectionEnd -= 1;
        }
        this.cursorLastPosition = {
          selectionStart: selectionEnd,
          selectionEnd: selectionEnd,
          code: null,
          isDeleteNumber: false,
        };
      }
      this.formatAndSetValue(actualValue);
    });
  }

  private treatPointForDay(actualValue: string) {
    const dayString = actualValue.substring(0, actualValue.length).replace('/', '');

    // add '0' to the start of the string if needed
    const paddedValue = dayString.length && dayString.length < 2 ? '0'.concat(dayString) : dayString;

    this.setValueToCalendar(paddedValue + '/');
  }

  private treatPointForMonth(actualValue: string) {
    // replace '/' from end of string
    const trimmedValue = actualValue.replace(/\/$/, '');
    let pointIndex = trimmedValue.lastIndexOf('/');
    const monthString = actualValue.substring(pointIndex + 1, actualValue.length).replace('/', '');
    const paddedValue = monthString.length && monthString.length < 2 ? '0'.concat(monthString) : monthString;
    const paddedActualValue = actualValue.substring(0, pointIndex + 1).concat(paddedValue);
    this.setValueToCalendar(paddedActualValue + '/');
  }

  private formatAndSetValue(atualValue: string) {
    // regex to replace everything except numbers, regex trim to 8 characters
    const trimmedValue = atualValue.replace(/[^0-9]/g, '').substring(0, 8);

    // set the new value
    let formattedValue: string;
    if (this.dateFormat === 'dd/mm/yy') {
      // regex to add points after 2 and 4 characters and trim to 10 characters
      formattedValue = trimmedValue
        .replace(/^(\d{2})(\d)/, '$1/$2')
        .replace(/^(\d{2})\/(\d{2})(\d)/, '$1/$2/$3')
        .substring(0, 10);
      if ([2, 5].includes(formattedValue.length)) {
        formattedValue = `${formattedValue}/`;
        this.cursorLastPosition!.selectionStart = this.cursorLastPosition!.selectionStart + 1;
        this.cursorLastPosition!.selectionEnd = this.cursorLastPosition!.selectionEnd + 1;
      }
      this.setValueToCalendar(formattedValue);
    } else {
      // regex to add points after 2 characters and trim to 7 characters
      formattedValue = trimmedValue.replace(/^(\d{2})(\d)/, '$1/$2').substring(0, 7);
      if (2 === formattedValue.length) {
        formattedValue = `${formattedValue}/`;
        this.cursorLastPosition!.selectionStart = this.cursorLastPosition!.selectionStart + 1;
        this.cursorLastPosition!.selectionEnd = this.cursorLastPosition!.selectionEnd + 1;
      }
      this.setValueToCalendar(formattedValue);
    }
  }

  private getValue(): string {
    if (this.cursorLastPosition?.isDeleteNumber) {
      return this.replaceAt(
        this.calendar.inputfieldViewChild.nativeElement.value,
        this.cursorLastPosition.selectionEnd - 1,
        '/'
      );
    }
    return this.calendar.inputfieldViewChild.nativeElement.value;
  }

  /**
   * Set value to the input field
   * @param val
   */
  private setValueToCalendar(val: any) {
    if (this.calendar.inputfieldViewChild.nativeElement.value !== val) {
      this.calendar.inputfieldViewChild.nativeElement.value = val;
      try {
        let value = this.calendar.parseValueFromString(val);
        // valid date
        if (this.calendar.isValidSelection(value)) {
          this.calendar.updateModel(value);
          this.calendar.updateUI();
        } else {
          this.calendar.updateModel(null);
          this.calendar.updateUI();
        }
      } catch (err) {
        //invalid date
        let value = this.calendar.keepInvalid ? val : null;
        this.calendar.updateModel(value);
      }
      this.calendar.filled = val != null && val.length;
    }
    if (this.cursorLastPosition && val?.length >= this.cursorLastPosition.selectionEnd) {
      this.calendar.inputfieldViewChild.nativeElement.setSelectionRange(
        this.cursorLastPosition.code === 'Backspace'
          ? this.cursorLastPosition.selectionEnd - 1
          : this.cursorLastPosition.selectionEnd,
        this.cursorLastPosition.code === 'Backspace'
          ? this.cursorLastPosition.selectionEnd - 1
          : this.cursorLastPosition.selectionEnd
      );
    } else if (this.cursorLastPosition) {
      this.calendar.inputfieldViewChild.nativeElement.setSelectionRange(
        this.cursorLastPosition.selectionEnd,
        this.cursorLastPosition.selectionEnd
      );
    }
    this.cursorLastPosition = null;
  }

  private setSelectionWhenDelete(event: KeyboardEvent) {
    if (event?.target) {
      const target = event.target as any;
      let selectionStart = target.selectionStart,
        selectionEnd = target.selectionEnd,
        isDeleteNumber = false;
      // nếu ký tự xoá là "/" thì selection - 1;
      if (target.value?.substring(0, selectionEnd)?.endsWith('/')) {
        selectionStart -= 1;
        selectionEnd -= 1;
        isDeleteNumber = true;
      }
      this.cursorLastPosition = {
        selectionStart: selectionStart,
        selectionEnd: selectionEnd,
        code: event.code as any,
        isDeleteNumber,
      };
    }
  }

  replaceAt(value: string, index: number, replacement: string) {
    return value.substring(0, index) + replacement + value.substring(index + 1);
  }
}
