import { ChangeDetectorRef, Component, ElementRef, forwardRef, Input, ViewChild } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators,
} from '@angular/forms';
import { getErrorMessage } from '@cores/utils/functions';
import { isEmpty } from 'lodash';
import * as moment from 'moment';
import { maxDate, maxDateFromNow, maxDateToday, minDate, minDateToday } from '@cores/utils/custom-validators';
import { TranslateService } from '@ngx-translate/core';
import { DATE_FORMAT, SERVER_DATE_FORMAT } from '@cores/utils/constants';
import { Calendar, CalendarTypeView } from 'primeng/calendar';

@Component({
  selector: 'lp-input-date',
  templateUrl: './lp-input-date.component.html',
  styleUrls: ['./lp-input-date.component.scss'],
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => LPInputDateComponent),
      multi: true,
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LPInputDateComponent),
      multi: true,
    },
  ],
})
export class LPInputDateComponent implements Validator, ControlValueAccessor {
  @Input() label: string = 'EMPTY';
  @Input() placeholder: string = 'EMPTY';
  @Input() showLabel: boolean = true;
  @Input() selectionMode: string = 'single'; // single, multiple, range
  @Input() required?: boolean | string;
  @Input() readonly: boolean = false;
  @Input() maxDateLabel: string = '';
  @Input() maxDateFromNowType: 'days' | 'weeks' | 'months' | 'years' = 'days';
  @Input() minDateLabel: string = '';
  @Input() dateFormat: 'dd/mm/yy' | 'mm/yy' = 'dd/mm/yy';
  @Input() disabled: boolean = true;
  @Input() border: boolean = true;
  @Input() timeOnly: boolean = false;
  @Input() view: CalendarTypeView = 'date'; //'date' | 'month' | 'year'
  @ViewChild('calendar') calendarInput?: Calendar;
  absControl!: AbstractControl;
  control = new FormControl<any>(null);
  initComp: boolean = false;

  constructor(
    private el: ElementRef<HTMLElement>,
    private cdr: ChangeDetectorRef,
    private translate: TranslateService
  ) {
    this.control.valueChanges.subscribe(value => {
      if (this.onChange) {
        const val = value instanceof Date ? moment(value).format(SERVER_DATE_FORMAT) : value;
        this.onChange(val);
      }
    });
  }

  _maxDateToday = false;

  get maxDateToday() {
    return this._maxDateToday;
  }

  @Input() set maxDateToday(value: boolean) {
    this._maxDateToday = value;
    if (this._maxDateToday) {
      if (moment(this.maxDate).isValid() && moment(new Date()).isAfter(moment(this.maxDate), 'day')) {
        return;
      } else {
        this.maxDate = moment().endOf('days').toDate();
      }
    }
  }

  _maxDateFromNow = 0;

  get maxDateFromNow(): number {
    return this._maxDateFromNow;
  }

  @Input() set maxDateFromNow(value: number) {
    this._maxDateFromNow = value;
    this.maxDate = moment().add(this._maxDateFromNow, this.maxDateFromNowType).toDate();
  }

  _minDateToday = false;

  get minDateToday() {
    return this._minDateToday;
  }

  @Input() set minDateToday(value: boolean) {
    this._minDateToday = value;
    if (this._minDateToday) {
      if (moment(this.minDate).isValid() && moment(new Date()).isBefore(moment(this.minDate), 'day')) {
        return;
      } else {
        this._minDate = moment().startOf('days').toDate();
      }
    }
  }

  _maxDate: any;

  get maxDate(): Date {
    return this._maxDate;
  }

  @Input() set maxDate(value: any) {
    if (typeof value === 'string') {
      const date: any = moment(value, SERVER_DATE_FORMAT);
      this._maxDate = new Date(date);
    } else if (value instanceof Date) {
      this._maxDate = value;
    } else {
      this._maxDate = undefined;
    }
    if (this.maxDateToday) {
      if (moment(this.maxDate).isValid() && moment(this.maxDate).isBefore(moment(new Date()))) {
        return;
      }
      this._maxDate = moment().endOf('days').toDate();
    }
  }

  _minDate: any;

  get minDate(): Date {
    return this._minDate;
  }

  @Input() set minDate(value: any) {
    if (typeof value === 'string') {
      const date: any = moment(value, SERVER_DATE_FORMAT);
      this._minDate = new Date(date);
    } else if (value instanceof Date) {
      this._minDate = value;
    } else {
      this._minDate = undefined;
    }

    if (this.minDateToday) {
      if (moment(this.minDate).isValid()) {
        if (moment(this.minDate).isAfter(moment(new Date()))) {
          return;
        }
      }
      this._minDate = moment().startOf('days').toDate();
    }
  }

  get errors() {
    return (
      (this.el.nativeElement.closest('.ng-submitted') || this.absControl?.touched || this.absControl?.dirty) &&
      this.absControl?.errors &&
      !this.readonly
    );
  }

  onBlur(event: any) {
    if (!this.control.value) {
      return;
    }
    if (!(this.control.value instanceof Date) && moment(this.control.value.trim(), 'DDMMYYYY', true).isValid()) {
      let trimmedValue = this.control.value.trim();
      const val = moment(trimmedValue, 'DDMMYYYY').format(SERVER_DATE_FORMAT).toString();
      const valToDisplay = moment(trimmedValue, 'DDMMYYYY').format(DATE_FORMAT).toString();
      this.control.setValue(valToDisplay);
      this.onChange(val);
    } else if (!(this.control.value instanceof Date)) {
      let trimmedValue = event.target.value.trim();
      if (trimmedValue !== '') {
        this.control.setValue(trimmedValue);
        this.onChange(trimmedValue);
      }
    }
  }

  onInput(event: any) {
    if (moment(event.target.value, DATE_FORMAT, true).isValid()) {
      const val = moment(event.target.value, DATE_FORMAT).format(SERVER_DATE_FORMAT).toString();
      this.onChange(val);
      return;
    }
    this.onChange(event.target.value);
  }

  onChange = (_value: any) => {};

  onTouched = () => {};

  // trimInput() {
  //   if (this.rawValue) {
  //     this.rawValue = this.rawValue.trim();
  //     this.control.setValue(this.rawValue);
  //   }
  // }

  //Lấy ra message lỗi validate để hiển thị, nếu có nhiều lỗi -> hiển thị lỗi đầu tiên.
  getError() {
    let errorKey = Object.keys(this.absControl.errors as object)[0];
    let errorValue: any = this.absControl.errors![errorKey];
    return getErrorMessage(errorKey, errorValue, this.translate.instant(this.label));
  }

  //Dùng để check trường hiện tại có phải required hay không.
  checkRequire() {
    return this.absControl?.hasValidator(Validators.required);
  }

  writeValue(value: string): void {
    if (moment(value, SERVER_DATE_FORMAT).isValid()) {
      let tmpDate = new Date(moment(value, SERVER_DATE_FORMAT).toString());
      this.control.setValue(tmpDate, { emitEvent: false });
    } else {
      this.control.setValue(value, { emitEvent: false });
    }
    this.initComp = true;
    if (this.absControl) {
      this.absControl.markAsPristine();
    }
  }

  registerOnChange(fn: (value: Date) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    if (isDisabled) {
      this.control.disable({ emitEvent: false });
    } else {
      this.control.enable({ emitEvent: false });
    }
  }

  // Đừng ai xoá chỗ này nha
  //   else if (this.minDate && this.maxDate) {
  //   return dateValue.isBetween(moment(this.minDate), moment(this.maxDate), 'day', '[]')
  // ? null
  //     : { minDateAndMaxDate: { minDateLabel: this.minDateLabel, maxDateLabel: this.maxDateLabel } };
  // }

  validate(control: AbstractControl): ValidationErrors | null {
    this.absControl = control;
    let dateValue = moment(control.value).isValid()
      ? moment(control.value).format(DATE_FORMAT)
      : this.calendarInput?.inputfieldViewChild?.nativeElement?.value;
    dateValue = dateValue?.replace(/[^0-9]/g, '').substring(0, 8);
    dateValue = dateValue
      ?.replace(/^(\d{2})(\d)/, '$1/$2')
      ?.replace(/^(\d{2})\/(\d{2})(\d)/, '$1/$2/$3')
      ?.substring(0, 10);
    if (!isEmpty(dateValue)) {
      if (!moment(dateValue, DATE_FORMAT, true).isValid()) {
        return { datePattern: { requiredPattern: DATE_FORMAT, actualValue: dateValue } };
      }

      let errors: any[] = [];
      dateValue = moment(dateValue, DATE_FORMAT);
      if (this.minDateToday) {
        errors.push(minDateToday(dateValue));
      }
      if (this.maxDateToday) {
        errors.push(maxDateToday(dateValue));
      }

      if (this.maxDateFromNow) {
        errors.push(maxDateFromNow(this.translate, dateValue, this.maxDateFromNow, this.maxDateFromNowType));
      }

      if (this.minDate) {
        errors.push(minDate(dateValue, this.minDate, this.translate.instant(this.minDateLabel || 'EMPTY')));
      }

      if (this.maxDate) {
        errors.push(maxDate(dateValue, this.maxDate, this.translate.instant(this.maxDateLabel || 'EMPTY')));
      }
      return errors.filter((e: any) => e)[0] || null;
    }
    return null;
  }
}
