import { Injectable } from '@angular/core';
import { Apollo, gql, Mutation } from 'apollo-angular';
import { UpdateMePayload } from '@generated';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  constructor(private apollo: Apollo) {}

  getDataProfile() {
    const get_request = gql`
      query {
        me {
          documents {
            identityPaper{
              id
            }
            identityNumber
          }
          username
          dob
          firstname
          lastname
          gender
          status
          roles {
            code
            name
            active
          }
          contacts {
            data
            type
          }
          merchant {
            viName
          }
          preferences
          addresses {
            address
            ward {
              code
              name
              district {
                code
                name
                province {
                  code
                  name
                  country {
                    code
                    enName
                    isoCode
                    viName
                  }
                }
              }
            }
          }
        }
      }
    `;
    return this.apollo.watchQuery<any>({
      //variables: { code: code },
      fetchPolicy: 'no-cache',
      query: get_request,
    }).valueChanges;
  }

  getNameProfile() {
    const get_request = gql`
      query {
        me {
          firstname
          lastname
        }
      }
    `;
    return this.apollo.watchQuery<any>({
      //variables: { code: code },
      fetchPolicy: 'no-cache',
      query: get_request,
    }).valueChanges;
  }

  update(data: UpdateMePayload) {
    const UPDATE_ME = gql`
      mutation ($payload: UpdateMePayload!) {
        updateMe(payload: $payload) {
          username
        }
      }
    `;
    return this.apollo.mutate<Mutation>({
      variables: { payload: data },
      mutation: UPDATE_ME,
    });
  }
}
