import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import { Observable } from 'rxjs';
import { SessionService } from './session.service';
import { StreamDataService } from './stream-data.service';
import { NotificationMessageService } from '@cores/services/message.service';
import { ProfileService } from '@features/profile/services/profile.service';
import { SessionKey } from '@cores/utils/enums';
import { popResponseError } from '@cores/utils/functions';

@Injectable({
  providedIn: 'root',
})
export class GuardService implements CanActivate {
  constructor(
    private streamData: StreamDataService,
    private sessionService: SessionService,
    private keycloakService: KeycloakService,
    private accountSerivce: ProfileService,
    private messageService: NotificationMessageService,
    private router: Router
  ) {}

  canActivate(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<boolean> {
    return new Observable<boolean>(_observable => {
      this.accountSerivce.getDataProfile().subscribe({
        next: data => {
          this.sessionService.setSessionData(SessionKey.UserProfile, data?.data);
          this.sessionService.setSessionData(SessionKey.Preferences, data?.data?.me?.preferences);
          return _observable.next(true);
        },
        error: err => {
          console.log(popResponseError(err));
          this.messageService.error(popResponseError(err));
          this.router.navigateByUrl('/not-allowed');
        },
      });
    });
  }
}
