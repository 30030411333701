import { AfterViewInit, Component, ElementRef, forwardRef, Input } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators,
} from '@angular/forms';
import { getErrorMessage } from '@cores/utils/functions';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'lp-input-tree-select',
  templateUrl: './lp-input-tree-select.component.html',
  styleUrls: ['./lp-input-tree-select.component.scss'],
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => LPInputTreeSelectComponent),
      multi: true,
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LPInputTreeSelectComponent),
      multi: true,
    },
  ],
})
export class LPInputTreeSelectComponent implements Validator, ControlValueAccessor, AfterViewInit {
  @Input() label: string = 'EMPTY';
  @Input() placeholder: string = 'EMPTY';
  @Input() showLabel: boolean = true;
  @Input() emptyMessage: string = '';
  @Input() readonly: boolean = false;
  @Input() border: boolean = false;
  @Input() selectionMode: string = 'single';
  @Input() metaKeySelection: boolean = false;
  initComp: boolean = false;

  absControl!: AbstractControl;
  control = new FormControl();
  nodes: any = [
    {
      label: 'Documents',
      data: 'Documents Folder',
      expandedIcon: 'pi pi-folder-open',
      collapsedIcon: 'pi pi-folder',
      children: [
        {
          label: 'Work',
          data: 'Work Folder',
          expandedIcon: 'pi pi-folder-open',
          collapsedIcon: 'pi pi-folder',
          children: [
            { label: 'Expenses.doc', icon: 'pi pi-file', data: 'Expenses Document' },
            { label: 'Resume.doc', icon: 'pi pi-file', data: 'Resume Document' },
          ],
        },
        {
          label: 'Home',
          data: 'Home Folder',
          expandedIcon: 'pi pi-folder-open',
          collapsedIcon: 'pi pi-folder',
          children: [{ label: 'Invoices.txt', icon: 'pi pi-file', data: 'Invoices for this month' }],
        },
      ],
    },
    {
      label: 'Pictures',
      data: 'Pictures Folder',
      expandedIcon: 'pi pi-folder-open',
      collapsedIcon: 'pi pi-folder',
      children: [
        { label: 'barcelona.jpg', icon: 'pi pi-image', data: 'Barcelona Photo' },
        { label: 'logo.jpg', icon: 'pi pi-file', data: 'PrimeFaces Logo' },
        { label: 'primeui.png', icon: 'pi pi-image', data: 'PrimeUI Logo' },
      ],
    },
    {
      label: 'Movies',
      data: 'Movies Folder',
      expandedIcon: 'pi pi-folder-open',
      collapsedIcon: 'pi pi-folder',
      children: [
        {
          label: 'Al Pacino',
          data: 'Pacino Movies',
          children: [
            { label: 'Scarface', icon: 'pi pi-video', data: 'Scarface Movie' },
            { label: 'Serpico', icon: 'pi pi-file-video', data: 'Serpico Movie' },
          ],
        },
        {
          label: 'Robert De Niro',
          data: 'De Niro Movies',
          children: [
            { label: 'Goodfellas', icon: 'pi pi-video', data: 'Goodfellas Movie' },
            { label: 'Untouchables', icon: 'pi pi-video', data: 'Untouchables Movie' },
          ],
        },
      ],
    },
  ];

  // nodes: any = [];

  constructor(private el: ElementRef<HTMLElement>, private translate: TranslateService) {
    this.control.valueChanges.subscribe(value => {
      if (this.onChange) {
        this.onChange(value);
      }
    });
  }

  get errors() {
    return (
      (this.el.nativeElement.closest('.ng-submitted') || this.absControl?.touched || this.absControl?.dirty) &&
      this.absControl?.errors &&
      !this.readonly
    );
  }

  trimData() {
    this.control?.setValue(this.control?.value?.trim() || '');
  }

  ngAfterViewInit() {
    console.log('init view');
  }

  onTreePanelShow() {
    const closeButton: any = this.el.nativeElement.querySelector('button.p-treeselect-close');
    closeButton.setAttribute('type', 'button');
  }

  onChange = (value: string) => {};

  onTouched = () => {};

  //Lấy ra message lỗi validate để hiển thị, nếu có nhiều lỗi -> hiển thị lỗi đầu tiên.
  getError() {
    let errorKey = Object.keys(this.absControl.errors as object)[0];
    let errorValue: any = this.absControl.errors![errorKey];
    return getErrorMessage(errorKey, errorValue, this.translate.instant(this.label));
  }

  //Dùng để check trường hiện tại có phải required hay không.
  checkRequire() {
    return this.absControl?.hasValidator(Validators.required);
  }

  writeValue(value: string): void {
    this.control.setValue(value, { emitEvent: false });
    if (this.absControl) {
      this.absControl.markAsPristine();
    }
  }

  registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    if (isDisabled) {
      this.control.disable({ emitEvent: false });
    } else {
      this.control.enable();
    }
  }

  validate(control: AbstractControl): ValidationErrors | null {
    this.absControl = control;
    return null;
  }
}
