<div class="table-container">
  <div class="table-body">
    <p-table
      #table
      (onRowSelect)="onRowSelect($event)"
      (selectionChange)="selectionMode === SelectionMode.Checkbox ? selectedNodeArray=$event : selectedNode=$event"
      [frozenValue]="frozenValue"
      [loading]="loadingService.loading"
      [rowHover]="true"
      [rowTrackBy]="trackByBody"
      [scrollable]="true"
      [selectionMode]="selectionMode"
      [selection]="selectionMode === SelectionMode.Checkbox ? selectedNodeArray : selectedNode"
      [showLoader]="false"
      [value]="dataTable?.content || []"
      responsiveLayout="scroll"
      scrollDirection="both"
      scrollHeight="flex">
      <!--      TABLE HEADER-->
      <ng-template pTemplate="header">
        <ng-container *ngIf="showHeader">
          <tr>
            <th scope="col" style="text-align: center; min-width: 60px">{{'table.index' | translate}}</th>
            <th *ngIf="selectionMode" scope="col">
              <p-tableHeaderCheckbox (click)="onCheckboxSelect()"
                                     *ngIf="selectionMode === SelectionMode.Checkbox"></p-tableHeaderCheckbox>
            </th>
            <ng-container *ngFor="let col of columns; trackBy: trackByHeader">
              <th
                *ngIf="!!col.visible"
                [ngStyle]="{
              'min-width': col.width
            }"
                scope="col">
                {{ col.headerName | translate }}
              </th>
            </ng-container>

          </tr>
        </ng-container>

      </ng-template>
      <!--      TABLE BODY-->

      <ng-template let-i="rowIndex" let-row pTemplate="frozenbody">

        <tr
          (click)="doClickRow($event, row)"
          (dblclick)="doDblClickRow($event, row)"
          [pSelectableRow]="row"
        >
          <td style="text-align: center; min-width: 60px">{{ i + 1 }}</td>
          <td *ngIf="selectionMode === SelectionMode.Single">
            <p-radioButton (click)="onRadioSelect();$event.stopPropagation(); "
                           [(ngModel)]="selectedNode"
                           [value]="row"
                           name="rowRadioSelect"></p-radioButton>

          </td>
          <td *ngIf="selectionMode === SelectionMode.Checkbox">
            <p-tableCheckbox (click)="onCheckboxSelect(); $event.stopPropagation()" [value]="row"></p-tableCheckbox>
          </td>
          <ng-container *ngFor="let col of columns; trackBy: trackByBody">
            <td
              *ngIf="!!col.visible"
              [ngStyle]="{
              'min-width': col.width
            }"
            >
              <ng-container *ngIf="col.tdTemplate; else noTemp">
                <ng-container *ngTemplateOutlet="col.tdTemplate; context:{ item: row }">
                </ng-container>
              </ng-container>
              <ng-template #noTemp>
                <ng-container *ngIf="col.type === columnDataType.Action">
                  <ng-container *ngTemplateOutlet="tdAction;context:{item: row}"></ng-container>
                </ng-container>

                <ng-container *ngIf="col.type !== columnDataType.Action && !col.tdTemplate">
                  <ng-container *ngIf="col.type;else noPipe">
                <span
                  [title]="(row|tableFieldPipe:col.fieldName|map:col.type)">{{row|tableFieldPipe:col.fieldName|map:col.type}}</span>
                  </ng-container>
                  <ng-template #noPipe>
                    <ng-container><span
                      [title]="row|tableFieldPipe:col.fieldName">{{row|tableFieldPipe:col.fieldName}}</span>
                    </ng-container>
                  </ng-template>
                </ng-container>
              </ng-template>

            </td>
          </ng-container>
        </tr>
      </ng-template>
      <ng-template let-i="rowIndex" let-row pTemplate="body">
        <tr
          (click)="doClickRow($event, row)"
          (dblclick)="doDblClickRow($event, row)"
          [pSelectableRow]="row"
        >
          <td style="text-align: center; min-width: 60px">{{ offset + i + 1 }}</td>
          <td *ngIf="selectionMode === SelectionMode.Single">
            <p-radioButton (click)="onRadioSelect();$event.stopPropagation(); "
                           [(ngModel)]="selectedNode"
                           [value]="row"
                           name="rowRadioSelect"></p-radioButton>

          </td>
          <td *ngIf="selectionMode === SelectionMode.Checkbox">
            <p-tableCheckbox (click)="onCheckboxSelect(); $event.stopPropagation()" [value]="row"></p-tableCheckbox>
          </td>
          <ng-container *ngFor="let col of columns; trackBy: trackByBody">
            <td
              *ngIf="!!col.visible"
              [ngStyle]="{
              'min-width': col.width
            }"
            >
              <ng-container *ngIf="col.tdTemplate; else noTemp">
                <ng-container *ngTemplateOutlet="col.tdTemplate; context:{ item: row }">
                </ng-container>
              </ng-container>
              <ng-template #noTemp>
                <ng-container *ngIf="col.type === columnDataType.Action">
                  <ng-container *ngTemplateOutlet="tdAction;context:{item: row}"></ng-container>
                </ng-container>

                <ng-container *ngIf="col.type !== columnDataType.Action && !col.tdTemplate">
                  <ng-container *ngIf="col.type;else noPipe">
                <span
                  [title]="(row|tableFieldPipe:col.fieldName|map:col.type)">{{row|tableFieldPipe:col.fieldName|map:col.type}}</span>
                  </ng-container>
                  <ng-template #noPipe>
                    <ng-container><span
                      [title]="row|tableFieldPipe:col.fieldName">{{row|tableFieldPipe:col.fieldName}}</span>
                    </ng-container>
                  </ng-template>
                </ng-container>
              </ng-template>

            </td>
          </ng-container>
        </tr>
      </ng-template>

      <ng-template let-columns pTemplate="emptymessage">
        <div class="table-empty-img">
          <img alt="No record found img" src="/assets/images/no-records.png"/>
          <span>{{ ('table.noRecord' | translate).toUpperCase() }}</span>
        </div>
      </ng-template>
    </p-table>
  </div>
  <div *ngIf="paginator" class="table-footer">
    <p-paginator
      #pa
      (onPageChange)="pageChange($event)"
      [first]="dataTable?.first || 0"
      [rowsPerPageOptions]="[10, 15, 20]"
      [rows]="dataTable?.size || 0"
      [showCurrentPageReport]="true"
      [totalRecords]="dataTable?.totalElements || 0"
      currentPageReportTemplate="{{ 'table.total' | translate }} {{ dataTable?.totalElements || 0 | number }} {{
      ('table.record' | translate).toLowerCase()
    }} / {{ dataTable?.totalPages || 0 | number }} {{ ('table.page' | translate).toLowerCase() }}"
      dropdownAppendTo="body"></p-paginator>
  </div>
</div>

<ng-template #tdAction let-item="item">
  <span (click)="menu.toggle($event)" (keyup.escape)="menu.hide()" class="lp-action action-cell action-icon"></span>
  <p-menu #menu [model]="getActionsItem(item?.invisibleActions)" [popup]="true" appendTo="body"></p-menu>
</ng-template>

