import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, of } from 'rxjs';
import { Apollo, gql, } from 'apollo-angular';
import { Query } from '@generated';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(private http: HttpClient, private apollo: Apollo) { }

  getBirthdayNotify() {
    return of(null);
  }
  notificationsByMe() {
    const GET_NOTIFICATION = gql`
    query{
      notificationsByMe{
        messageIds
        data
        type
      }
    }
    `
    return this.apollo.watchQuery<Query>({
      query: GET_NOTIFICATION,
      fetchPolicy: 'no-cache'
    }).valueChanges.pipe(map(res => res?.data?.notificationsByMe));
  }
}
