import { animate, style, transition, trigger } from '@angular/animations';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivationEnd, Router } from '@angular/router';
import * as _ from 'lodash';
import { getNodeMenuByUrl, getUrlPathName, popResponseError } from '@cores/utils/functions';
import { MenuModel } from '@cores/models/menu.model';
import { HttpCancelService } from '@cores/services/http-cancel.service';
import { SessionService } from '@cores/services/session.service';
import { FunctionModel } from '@cores/models/function.model';
import { KeycloakService } from 'keycloak-angular';
import { SessionKey } from '@cores/utils/enums';
import { UserManagementService } from '@userManagement/services/user-management.service';
import { ProfileService } from '@features/profile/services/profile.service';
import { FireBaseMessagingService } from '@cores/services/firebase-messaging.service';
import { NotificationService } from '@cores/services/notification.service';
import { NotificationMessageService } from '@cores/services/message.service';

interface DataNotifications {
  body?: string
}

interface Notifications {
  collapseKey?: String
  from?: String,
  messageId?: String,
  data?: DataNotifications
}
@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  animations: [
    trigger('toggleMenu', [
      transition(':enter', [style({ visibility: 'visible', height: 0 }), animate(`${150}ms ease-in`)]),
      transition(':leave', [animate(`${150}ms ease-out`, style({ visibility: 'hidden', height: 0 }))]),
    ]),
  ],
})
export class AppMenuComponent implements OnInit {
  @Output() staticMenu: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() numNotifi?: number | null
  classMenu = 'layout-sidebar';
  items: MenuModel[] = [];
  listMenuOfUser: FunctionModel[] = [];
  isLock = false;
  prevUrl = '/';
  pathImage = '/assets/images/logo-only.png';
  currUser: any;
  currentMenuItem?: MenuModel;
  openNotifi: boolean = false
  constructor(
    private router: Router,
    private httpCancelService: HttpCancelService,
    private sessionService: SessionService,
    private userManagementService: UserManagementService,
    private profileService: ProfileService,
    private cdr: ChangeDetectorRef,
    private keycloak: KeycloakService,
    private fBMessagingService: FireBaseMessagingService,
    private notificationService: NotificationService,
    private messageService: NotificationMessageService,

  ) {
    this.prevUrl = getUrlPathName(this.router.url);
    this.router.events.subscribe(event => {
      if (event instanceof ActivationEnd) {
        const currUrl = getUrlPathName(this.router.url);
        this.currentMenuItem = getNodeMenuByUrl({ children: this.items }, currUrl);
        if (this.currentMenuItem) {
          this.currentMenuItem.active = true;
          this.setActiveMenu(this.items, this.currentMenuItem);
        }
        if (this.prevUrl !== currUrl) {
          this.httpCancelService.cancelPendingRequests();
        }
        this.prevUrl = currUrl;
      }
    });
    this.userManagementService.onMeUpdate.subscribe(() => {
      this.profileService.getNameProfile().subscribe({
        next: value => {
          this.currUser = value.data?.me;
          this.cdr.detectChanges();
          this.sessionService.setSessionData(SessionKey.UserProfile, value.data?.me);
        },
      });
    });
    this.fBMessagingService.currentMessage?.subscribe((data: Notifications) => {
      if (data?.data?.body) {
        setTimeout(() => {
          this.getNumNotifi();
        }, 500);
      }
    })
  }

  ngOnInit() {
    this.getNumNotifi();
    this.currUser = this.sessionService.getSessionData(SessionKey.UserProfile)?.me;
    // const dataMenu = this.sessionService.getSessionData(SessionKey.Menu);
    // this.listMenuOfUser = dataMenu.menuOfUser;
    // dataMenu.menu = dataMenu.menu?.map((itemM: any) => {
    //   return {
    //     id: itemM.id,
    //     label: itemM.code,
    //     icon: itemM.iconUri,
    //     order: itemM.order,
    //     routerLink: itemM.uri,
    //     active: false,
    //     parentId: itemM.parent,
    //   };
    // });
    const dataMenu: any = {};

    dataMenu.menu = [
      {
        id: '1',
        label: 'administration',
        icon: 'lp-administration bg-primary-dark',
        order: 10,
        routerLink: undefined,
        active: false,
        parentId: undefined,
      },
      {
        id: '2',
        label: 'category',
        icon: null,
        order: 1,
        routerLink: '/administration/category',
        active: false,
        parentId: '1',
      },
      {
        id: '3',
        label: 'usermanagement',
        icon: null,
        order: 2,
        routerLink: '/administration/user',
        active: false,
        parentId: '1',
      },
      {
        id: '4',
        label: 'campaign',
        icon: 'lp-campaign bg-primary-dark',
        order: 2,
        routerLink: '/campaign/list',
        active: false,
        parentId: undefined,
      },
      {
        id: '5',
        label: 'merchantmanagement',
        icon: null,
        order: 3,
        routerLink: '/administration/merchant',
        active: false,
        parentId: '1',
      },
      {
        id: '6',
        label: 'functionmanagement',
        icon: null,
        order: 4,
        routerLink: '/administration/function',
        active: false,
        parentId: '1',
      },
      {
        id: '7',
        label: 'customer',
        icon: 'lp-customer bg-primary-dark',
        order: 3,
        routerLink: '/customer/list',
        active: false,
        parentId: undefined,
      },
      {
        id: '8',
        label: 'product',
        icon: 'lp-product bg-primary-dark',
        order: 4,
        routerLink: '/product',
        active: false,
        parentId: undefined,
      },
      {
        id: '9',
        label: 'domain',
        icon: null,
        order: 5,
        routerLink: '/administration/domain',
        active: false,
        parentId: '1',
      },
      {
        id: '10',
        label: 'authorization',
        icon: null,
        order: 6,
        routerLink: '/administration/authorization',
        active: false,
        parentId: '1',
      },
      {
        id: '11',
        label: 'calendar',
        icon: 'lp-calendar bg-primary-dark',
        order: 1,
        routerLink: '/calendar',
        active: false,
        parentId: undefined,
      },
      {
        id: '12',
        label: 'sales',
        icon: 'lp-sales bg-primary-dark',
        order: 8,
        routerLink: '/sales',
        active: false,
        parentId: undefined,
      },
      // {
      //   id: '13',
      //   label: 'sales-handbook',
      //   icon: 'lp-sales-handbook bg-primary-dark',
      //   order: 9,
      //   routerLink: '/sales-handbook',
      //   active: false,
      //   parentId: undefined,
      // },
    ];

    dataMenu.menu = _.orderBy(dataMenu.menu, 'order');
    this.items = dataMenu.menu
      .filter((o: any) => !o.parentId)
      .map((item: MenuModel, i: number) => {
        return { ...item, path: `[${i}]` };
      });
    this.mapTreeFunction(dataMenu.menu, this.items);
    // console.log(this.items);

    const currUrl = getUrlPathName(this.router.url);
    this.currentMenuItem = getNodeMenuByUrl({ children: this.items }, currUrl);
    if (this.currentMenuItem) {
      this.currentMenuItem.active = true;
      this.activeMenuItem(this.currentMenuItem);
    }
  }

  mapTreeFunction(list: MenuModel[], listParent: MenuModel[], path?: string) {
    listParent?.forEach((item, i) => {
      const listChildren = list?.filter(itemMenu => itemMenu.parentId === item.id);
      item.path = path ? `${path}.children[${i}]` : `[${i}]`;
      if (listChildren.length > 0) {
        item.children = listChildren;
        item.expanded = false;
        this.mapTreeFunction(list, item.children, item.path);
      }
    });
  }

  mouseEnterMenu() {
    this.pathImage = '/assets/images/logo.png';
    this.classMenu = 'layout-sidebar layout-sidebar-active';
    this.staticMenu.emit(true);
  }

  mouseLeaveMenu() {
    this.openNotifi = false
    this.pathImage = '/assets/images/logo-only.png';
    this.classMenu = 'layout-sidebar';
    this.staticMenu.emit(false);
  }
  // activeMenuItem(item: MenuModel) {
  //   if (item && item.routerLink !== this.prevUrl) {
  //     item.active = !item.active;
  //     if (item.routerLink) {
  //       this.prevUrl = item.routerLink;
  //     }
  //     if (item.active) {
  //       const listSibling = item.path.includes('.')
  //         ? _.get(this.items, item.path.substring(0, item.path.lastIndexOf('.')))?.children
  //         : this.items;
  //       for (const itemS of listSibling) {
  //         if (item.id === itemS.id) {
  //           continue;
  //         }
  //         itemS.active = false;
  //         this.setActiveMenu(itemS.children || [], item.routerLink, false);
  //       }
  //     } else {
  //       this.setActiveMenu(item.children || [], item.routerLink, false);
  //     }
  //   }
  // }
  //
  // setActiveMenu(data: MenuModel[], currUrl: string, active: boolean) {
  //   for (const item of data) {
  //     if (item.routerLink !== currUrl) {
  //       item.active = active;
  //     }
  //     if (item.children) {
  //       this.setActiveMenu(item.children, currUrl, active);
  //     }
  //   }
  // }

  activeMenuItem(item?: MenuModel) {
    if (item) {
      if (_.size(item.children) > 0) {
        item.active = !item.active;
      } else {
        item.active = true;
      }
      this.setActiveMenu(this.items, item);
    }
  }

  setActiveMenu(data: MenuModel[], itemSelect: MenuModel) {
    for (const item of data) {
      item.active =
        itemSelect.path?.startsWith(item.path) && itemSelect.path.length >= item.path.length && itemSelect.active;

      if (item.children) {
        item.expanded = item.active;
        this.setActiveMenu(item.children, itemSelect);
      }
    }
  }

  onStaticMenu() {
    this.isLock = !this.isLock;
    this.staticMenu.emit(this.isLock);
  }

  checkRole(itemCheck: MenuModel) {
    return true;
    // for (const item of this.listMenuOfUser) {
    //   if (itemCheck.label === FunctionCode.Dashboard || item.rsid === itemCheck.id) {
    //     return true;
    //   }
    // }
    // return false;
  }

  logout() {
    localStorage.clear();
    this.keycloak.logout().then();
  }

  routingProfile(): void {
    this.router.navigate(['/profile']).then();
  }

  showNotifi() {
    this.openNotifi = true
  }
  async getNumNotifi() {
    await this.notificationService.notificationsByMe().subscribe({
      next: res => {
        this.numNotifi = res?.length
      },
      error: err => {
        this.messageService.error(popResponseError(err));
      }
    })
  }
}
