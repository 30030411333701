import { KeycloakService } from 'keycloak-angular';
import { environment } from '@env';
import { HttpClient } from '@angular/common/http';
import { IModuleTranslationOptions, ModuleTranslateLoader } from '@larscom/ngx-translate-module-loader';

export function initializer(keycloak: KeycloakService): () => Promise<any> {
  return (): Promise<any> => {
    return new Promise(async (resolve, reject) => {
      try {
        await keycloak.init({
          config: {
            url: environment.keycloak.issuer,
            realm: environment.keycloak.realm,
            clientId: environment.keycloak.client_id,
          },
          loadUserProfileAtStartUp: true,
          initOptions: {
            onLoad: 'login-required',
            checkLoginIframe: false,
            //checkLoginIframeInterval: 5,
          },
          bearerPrefix: 'Bearer',
        });
        resolve(true);
      } catch (error) {
        reject(error);
      }
    });
  };
}

export function ModuleHttpLoaderFactory(http: HttpClient) {
  const baseTranslateUrl = '/assets/i18n';
  const options: IModuleTranslationOptions = {
    modules: [
      { baseTranslateUrl },
      { baseTranslateUrl, moduleName: 'dashboard', namespace: 'dashboard' },
      { baseTranslateUrl, moduleName: 'category', namespace: 'category' },
      { baseTranslateUrl, moduleName: 'customer', namespace: 'customer' },
      { baseTranslateUrl, moduleName: 'user-management', namespace: 'userManagement' },
      { baseTranslateUrl, moduleName: 'user-profile', namespace: 'user-profile' },
      { baseTranslateUrl, moduleName: 'campaign', namespace: 'campaign' },
      { baseTranslateUrl, moduleName: 'merchant-management', namespace: 'merchantManagement' },
      { baseTranslateUrl, moduleName: 'function-management', namespace: 'functionManagement' },
      { baseTranslateUrl, moduleName: 'product', namespace: 'product' },
      { baseTranslateUrl, moduleName: 'calendar', namespace: 'calendar' },
      { baseTranslateUrl, moduleName: 'domain', namespace: 'domain' },
      { baseTranslateUrl, moduleName: 'authorization', namespace: 'authorization' },
      { baseTranslateUrl, moduleName: 'inventory', namespace: 'inventory' },
      { baseTranslateUrl, moduleName: 'sales', namespace: 'sales' },
    ],
  };
  return new ModuleTranslateLoader(http, options);
}
