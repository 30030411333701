import { Component, OnInit, Injector } from '@angular/core';
import { NotificationService } from '@cores/services/notification.service';
import { Notification } from '@generated';
import { popResponseError } from '@cores/utils/functions';
import { BaseComponent } from '@shared/components';
import { animate, style, transition, trigger } from '@angular/animations';

interface Common {
  id: number,
  label: string,
  select: boolean,
  type: string,
  typeFilter?: string
}

interface ItemNotification {
  from: string,
  name: string,
  mess: string,
  type: string,
}
enum TypeTab {
  all = 'ALL',
  job = 'JOB',
}
enum TypeCheckBoxFilter {
  referral = 'REFERRAL',
  assign = 'ASSIGNMENT',
  closeSale = 'CLOSESALE',
  salesOpp = "SALESOPP",
  lead = "LEAD"
}
enum TypeActivity {
  sms = 'SMS',
  email = 'EMAIL',
  call = 'CALL',
  meeting = "MEETING"
}
@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
  animations: [
    trigger('toggleMenu', [
      transition(':enter', [style({ visibility: 'visible', height: 0 }), animate(`${150}ms ease-in`)]),
      transition(':leave', [animate(`${150}ms ease-out`, style({ visibility: 'hidden', height: 0 }))]),
    ]),
  ],
})
export class NotificationsComponent extends BaseComponent implements OnInit {
  listNotifications?: ItemNotification[] = [];
  _listNotifications?: ItemNotification[] = [];
  dataFilter: ItemNotification[] = []
  listSelected: Common[] = []
  tabs?: Common[] = [
    {
      id: 1,
      label: this.translate.instant('notifications.all'),
      select: true,
      type: TypeTab.all
    },
    {
      id: 2,
      label: this.translate.instant('notifications.job'),
      select: false,
      type: TypeTab.job
    }
  ]
  _listFilter?: Common[] = []
  //
  listFilter?: Common[] = [
    {
      id: 1,
      label: this.translate.instant('notifications.salesClosing'),
      select: false,
      type: TypeTab.job,
      typeFilter: TypeCheckBoxFilter.closeSale
    },
    {
      id: 2,
      label: this.translate.instant('notifications.salesOpp'),
      select: false,
      type: TypeTab.job,
      typeFilter: TypeCheckBoxFilter.salesOpp
    },
    {
      id: 3,
      label: this.translate.instant('notifications.potentialCustomer'),
      select: false,
      type: TypeTab.job,
      typeFilter: TypeCheckBoxFilter.lead
    },
    {
      id: 4,
      label: this.translate.instant('notifications.assign'),
      select: false,
      type: TypeTab.all,
      typeFilter: TypeCheckBoxFilter.assign
    },
    {
      id: 5,
      label: this.translate.instant('notifications.transferCrossSell'),
      select: false,
      type: TypeTab.all,
      typeFilter: TypeCheckBoxFilter.referral
    }
  ]
  constructor(injector: Injector, private notificationService: NotificationService) {
    super(injector);
  }

  ngOnInit(): void {
    this.getNotifications();
  }
  getNotifications() {
    this.loadingService.start();
    this.notificationService.notificationsByMe().subscribe({
      next: res => {
        this.listNotifications = res?.map((res: any) => {
          return {
            from: res?.data?.body.slice(0, res?.data?.body?.indexOf(":")),
            name: res?.data?.body.slice(res?.data?.body?.indexOf(":") + 1, res?.data?.body?.indexOf("được")),
            mess: res?.data?.body.slice(res?.data?.body?.indexOf("được"), res?.data?.body?.length),
            type: res?.type
          } as ItemNotification
        })
        this._listFilter = this.listFilter?.filter((element: Common) => element.type === TypeTab.all);
        this._listNotifications = [...this.listNotifications!]
        this.loadingService.complete();
      },
      error: err => {
        this.messageService?.error(popResponseError(err));
        this.loadingService.complete();
      }
    })
  }
  enableBtn(id: number, select: boolean, type: string) {
    if (!select) {
      this.tabs?.forEach((element: Common) => {
        if (element.id === id) {
          element.select = true
        } else {
          element.select = false
        }
      })
      this._listNotifications = []
      if (type === TypeTab.all) {
        this._listFilter = this.listFilter?.filter((element: Common) => element.type === TypeTab.all)
        this._listNotifications = [...this.listNotifications!]
      } else {
        this._listFilter = this.listFilter?.filter((element: Common) => element.type === TypeTab.job)
        this._listNotifications = this.listNotifications?.filter((element: ItemNotification) => element?.type && element?.type !== TypeCheckBoxFilter.assign && element?.type !== TypeCheckBoxFilter.referral)
      }
    }
  }
  onChangeCheckBox(data: any, id: number, typeSearch: string) {
    if (data.checked && (this.listSelected?.length < this._listFilter!.length)) {
      const checked = this._listFilter?.find((res: Common) => res?.id === id) as Common
      this.listSelected?.push(checked);
      let item: ItemNotification[] = []
      this.listSelected?.forEach(() => {
        item = this.listNotifications?.filter((elementNoti: ItemNotification) => elementNoti?.type === typeSearch)!;
      })
      item?.forEach((e: ItemNotification) => {
        this.dataFilter.push(e)
      })
      this._listNotifications = [...this.dataFilter!]
    }
    if (!data.checked) {
      this.removeDataFilter(id, typeSearch)
    }
  }
  removeItemSelected(id: number, typeSearch: string) {
    if (this.listSelected?.length) {
      this.listSelected = this.listSelected?.filter((res: Common) => res?.id !== id)
      this._listFilter?.forEach((element: Common) => {
        if (element.id === id) {
          element.select = false
        }
      })
      this.removeDataFilter(id, typeSearch)
    }
  }
  removeDataFilter(id: number, typeSearch: string) {
    let item: ItemNotification[] = []
    this.dataFilter = []
    this.listSelected?.forEach(() => {
      item = this._listNotifications?.filter((elementNoti: ItemNotification) => elementNoti?.type !== typeSearch)!;
    })
    item?.forEach((e: ItemNotification) => {
      this.dataFilter.push(e)
    })
    this._listNotifications = [...this.dataFilter!]
    this.listSelected = this.listSelected?.filter((res: Common) => res?.id !== id)
    if (!this.listSelected.length) {
      this._listNotifications = [...this.listNotifications!]
    }
  }
  convertIcon(type?: string) {
    const data = {
      class: '',
      icon: ''
    }
    if (type === TypeCheckBoxFilter.referral) {
      data.class = 'p-button-outlined p-0'
      data.icon = 'lp-transfer-cross-sell bg-primary-main'
    }
    if (type === TypeCheckBoxFilter.assign) {
      data.class = 'p-button-secondary p-button-outlined p-0'
      data.icon = 'lp-transfer-user bg-primary-dark'
    }
    if (type === TypeActivity.sms) {
      data.class = 'p-button-outlined p-0'
      data.icon = 'lp-sms bg-primary-main'
    }
    if (type === TypeActivity.call) {
      data.class = 'p-button-outlined p-button-success p-0'
      data.icon = 'lp-call bg-success-alternative-dark'
    }
    if (type === TypeActivity.meeting) {
      data.class = 'p-button-import p-button-outlined p-0'
      data.icon = 'lp-meeting warning-status'
    }
    if (type === TypeActivity.email) {
      data.class = 'p-button-secondary p-button-outlined p-0'
      data.icon = 'lp-email bg-primary-dark'
    }
    return { ...data }
  }
}
