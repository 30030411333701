<app-loading *ngIf="isLoading"></app-loading>
<div class="p-dialog-header">
  <span class="p-dialog-title">{{ 'dialog.header.merchantList' | translate }}</span>
  <div class="p-dialog-header-icons">
    <button (click)="cancel()" class="p-dialog-header-icon p-dialog-header-maximize p-link" type="button">
      <span class="p-dialog-header-close-icon pi pi-times"></span>
    </button>
  </div>
</div>
<div class="p-fluid p-dialog-body">
  <div class="container">
    <div class="search-section">
      <div class="grid field">
        <div [formGroup]="params" class="col-12 md:col-6 lg:col-4 mb-3">
          <lp-input-select [filter]="true" [options]="droplistData.merchantTypes" formControlName="typeCode"
                           label="merchantManagement.label.merchantType"></lp-input-select>
        </div>
        <div [formGroup]="params" class="col-12 md:col-6 lg:col-4 mb-3">
          <lp-input-select [options]="droplistData.merchantLevels" formControlName="levelCode"
                           label="merchantManagement.label.merchantParent"></lp-input-select>
        </div>
        <div [formGroup]="params" class="col-12 md:col-6 lg:col-4 mb-3">
          <lp-input-text (keyup.enter)="this.search(true)" formControlName="viName"
                         label="merchantManagement.label.merchantViName"></lp-input-text>
        </div>
        <div [formGroup]="params" class="col-12 md:col-6 lg:col-4">
          <lp-input-select [options]="statusDroplist" formControlName="active"
                           label="merchantManagement.label.status"></lp-input-select>
        </div>
        <div [formGroup]="params" class="col-12 md:col-6 lg:col-4">
          <lp-input-text (keyup.enter)="this.search(true)" formControlName="parentName"
                         label="merchantManagement.label.merchantParentName"></lp-input-text>
        </div>
      </div>
    </div>
    <div class="action-section">
      <div class="section-action-left">
        <button (click)="search(true)" class="p-button-outlined p-button-secondary" icon="las la-search"
                label="{{ 'button.search' | translate }}"
                pButton></button>
        <button (click)="reload()" class="p-button-outlined ml-2" icon="las la-redo-alt"
                pButton pTooltip="{{ 'button.reload' | translate}}"></button>
      </div>
    </div>
    <div class="table-container mt-3">
      <lp-table (onPageChange)="pageChange($event)" [(selectedNode)]="selectedNode" [columns]="columns"
                [dataTable]="dataTable" selectionMode="single"></lp-table>
    </div>
  </div>
</div>
<div class="p-dialog-footer">
  <button (click)="save()" class="p-button-outlined" icon="pi pi-check" label="{{'button.confirm' | translate}}"
          pButton></button>
  <button (click)="cancel()" class="p-button-cancel p-button-outlined" icon="pi pi-times"
          label="{{'button.close' | translate}}" pButton></button>
</div>

<ng-template #status let-item="item">
  <span [ngClass]="{'active-status': item.active, 'inactive-status': !item.active}">
                    {{ item.active ? ('common.active' | translate) : ('common.inactive' | translate) }}
                </span>
</ng-template>
