import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { BehaviorSubject } from 'rxjs'
import { Apollo, Mutation, gql } from 'apollo-angular';
import { NotificationMessageService } from './message.service';
import { popResponseError } from '@cores/utils/functions';
import { LoadingService } from './loading.service';
@Injectable({ providedIn: 'root' })
export class FireBaseMessagingService {
  currentMessage?= new BehaviorSubject({})
  constructor(
    private angularFireMessaging: AngularFireMessaging,
    private apollo: Apollo,
    private messageService: NotificationMessageService,
    private loadingService: LoadingService
  ) {
    this.angularFireMessaging.messages.subscribe((_messages: any) => {
      _messages.onMessage = _messages.onMessage.bind(_messages);
      _messages.onTokenRefresh = _messages.onTokenRefresh.bind(_messages);
    }
    )
  }
  // đăng ký nhận message
  requestPermission() {
    this.loadingService.start();
    this.angularFireMessaging.requestToken.subscribe({
      next: token => {
        if (token) {
          this.addUserDevice(token).subscribe({
            next: res => {
              this.loadingService.complete();
            },
            error: err => {
              this.loadingService.complete();
              this.messageService.error(popResponseError(err));
            }
          })
        }
      },
      error: err => {
        this.messageService.error(popResponseError(err));
      }
    }
    )
  }
  // theo dõi , lấy ra mess gần nhất
  receiveMessage() {
    this.angularFireMessaging.messages.subscribe({
      next: res => {
        this.currentMessage?.next(res)
      },
      error: err => {
        this.messageService.error(popResponseError(err));
      }
    })
  }
  // add token theo từng thiết bị
  addUserDevice(token: string) {
    const ADD_USER_DEVICE = gql`
    mutation ($deviceToken: String!) {
      addUserDevice(deviceToken: $deviceToken) {
        username
      }
    }
    `
    return this.apollo.mutate<Mutation>({
      variables: { deviceToken: token },
      mutation: ADD_USER_DEVICE,
    })
  }

}
