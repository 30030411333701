import { ChangeDetectorRef, Component, Injector, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { BaseTableComponent } from '@shared/components';
import { CategoryCode, ColumnDataType } from '@cores/utils/enums';
import { mapDataTable, popResponseError } from '@cores/utils/functions';
import { CategoryService } from '@category/services/category.service';
import { DropList, ParamDropList } from '@cores/models/drop.list';
import { Column } from '@cores/models/data-table.model';
import { MerchantManagementService } from '@features/administration/merchant-management/services/merchant-management.service';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { STATUS_DROPLIST } from '@cores/utils/constants';
import { MerchantLevel } from '@generated';

@Component({
  selector: 'search-merchant-popup',
  templateUrl: './search-merchant.component.html',
  styleUrls: ['./search-merchant.component.scss'],
})
export class SearchMerchantComponent extends BaseTableComponent implements OnInit {
  data: any;
  isLoading = false;
  override params = this.fb.group({
    active: null,
    viName: null,
    levelCode: null,
    typeCode: null,
    parentName: null,
  });
  statusDroplist = STATUS_DROPLIST;

  override columns: Column[] = [
    {
      headerName: 'merchantManagement.label.merchantName',
      visible: true,
      width: '267px',
      fieldName: 'viName',
      type: ColumnDataType.Text,
    },
    {
      headerName: 'merchantManagement.label.merchantParentName',
      visible: true,
      width: '270px',
      fieldName: 'parent.viName',
      type: ColumnDataType.Text,
    },
    {
      headerName: 'merchantManagement.label.merchantType',
      visible: true,
      width: '200px',
      fieldName: 'type.name',
      type: ColumnDataType.Text,
    },
    {
      headerName: 'Level',
      visible: true,
      width: '200px',
      fieldName: 'level.name',
      type: ColumnDataType.Text,
    },
    {
      headerName: 'merchantManagement.label.status',
      visible: true,
      width: '168px',
      fieldName: 'active',
    },
  ];

  @ViewChild('status', { static: true }) status!: TemplateRef<any>;
  droplistData: DropList = {
    merchantTypes: [],
    merchantLevels: [],
  };

  columnDataType = ColumnDataType;
  selectedNode: any;

  constructor(
    injector: Injector,
    private merchantService: MerchantManagementService,
    private categoryService: CategoryService,
    private cdr: ChangeDetectorRef,
    private dialogRef: DynamicDialogRef
  ) {
    super(injector);
  }

  get CategoryCode() {
    return CategoryCode;
  }

  log(event: any) {
    console.log(event);
  }

  save() {
    this.dialogRef.close(this.selectedNode);
  }

  override reload() {
    this.params.reset();
    super.reload();
  }

  override search(firstPage?: boolean) {
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;
    const params = this.mapDataSearch(!!firstPage);
    this.merchantService.search(params).subscribe({
      next: res => {
        const data = res?.data.pageMerchant;
        this.dataTable = mapDataTable(data, params);
        this.isLoading = false;
        this.prevParams = params;
        delete this.prevParams!.limit;
        delete this.prevParams!.offset;
      },
      error: err => {
        this.messageService?.error(popResponseError(err));
        this.isLoading = false;
      },
    });
  }

  ngOnInit() {
    this.columns[4].tdTemplate = this.status;
    this.isLoading = true;
    const paramsDroplist: ParamDropList = {
      merchantType: true,
      merchantLevel: true,
    };
    this.categoryService.getCommonCategory(paramsDroplist).subscribe({
      next: value => {
        this.droplistData = { ...value };
        this.droplistData.merchantLevels = this.droplistData?.merchantLevels?.filter((e: MerchantLevel) => e.active);
        this.isLoading = false;
        this.search(true);
      },
      error: err => {
        this.messageService.error(popResponseError(err));
        this.isLoading = false;
        this.search(true);
      },
    });
  }
}
