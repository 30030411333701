import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import {
  ActivityResult,
  ActivityType,
  Common,
  CommonCategoryFilter,
  CommonCategoryPayload,
  Country,
  CreateCommonPayload,
  District,
  IdentityPaper,
  MaritalStatus,
  Maybe,
  MerchantLevel,
  MerchantType,
  Mutation,
  Occupation,
  ProductAttribute,
  Province,
  Query,
  RelationshipType,
  RelationshipTypeApplyTo,
  Role,
  UpdateCommonPayload,
  Ward,
} from '@generated';
import { removeParamNullOrUndefined } from '@cores/utils/functions';
import { map } from 'rxjs';
import { DropList, ParamDropList } from '@cores/models/drop.list';
import * as _ from 'lodash';
import { ApolloQueryResult } from '@apollo/client/core';
import { CategoryCode } from '@cores/utils/enums';

const QUERY_BY_CATEGORY = {
  MaritalStatus: `maritalStatuses {
          id
          name
        }`,
  PaperType: `identityPapers {
          id
          name
        }`,
  RelationshipType: `relationshipTypes {
          code
          name
          applyTo
        }`,
  MerchantType: `merchantTypes {
          code
          name
        }`,
  ActivityType: `activityTypes{
          code
          description
        }`,
  ActivityResult: `activityResults {
          id
          type {
            code
          }
          description
        }`,
  ProductAttribute: `
        productAttributes {
          code
          label
          options {
            label
            value
          }
        }
      `,
  MerchantLevel: `merchantLevels {
          code
          name
          active
        }`,
  Occupation: `occupations {
          id
          name
        }`,
  CategoryCodes: `commonCategoriesByCodes(codes: $categoryCodes) {
      code
      values {
        code
        name
        description
        value
        isDefault
        orderNumber
      }
    }`,
  Countries: `countries{
      code
      isoCode
      viName
      enName
    }`,
  ProvincesByCountryCode: `provincesByCountryCode(countryCode: $countryCode){
      code
      name
    }`,
  DistrictsByProvinceCode: `districtsByProvinceCode(provinceCode: $provinceCode){
      code
      name
    }`,
  WardsByDistrictCode: `wardsByDistrictCode(districtCode: $districtCode){
      code
      name
    }`,
  Roles: `roles {
    code
    name
  }`,
};

@Injectable({
  providedIn: 'root',
})
export class CategoryService {
  constructor(private apollo: Apollo) {}

  getCommonCategory(params: ParamDropList) {
    return this.apollo
      .watchQuery<Query>({
        query: gql`
          ${this.mapQueryByParam(params)}
        `,
        variables: params,
        fetchPolicy: 'no-cache',
      })
      .valueChanges.pipe(
        map((res: ApolloQueryResult<Query>) => {
          return this.mapDataModelDropList(res.data, params);
        })
      );
  }

  findByCode(code: string) {
    const QUERY = gql`
      query ($code: String!) {
        commonCategoryByCode(code: $code) {
          code
          description
          name
          active
          values {
            code
            name
            description
            value
            isDefault
            orderNumber
          }
        }
      }
    `;
    return this.apollo.watchQuery<Query>({
      variables: { code: code },
      query: QUERY,
      fetchPolicy: 'no-cache',
    }).valueChanges;
  }

  search(params?: CommonCategoryFilter) {
    params = removeParamNullOrUndefined(params);
    const GET_CATEGORY = gql`
      query ($filter: CommonCategoryFilter!) {
        pageCommonCategory(filter: $filter) {
          count
          list {
            code
            name
            description
            active
            values {
              code
              name
              description
              value
              active
              isDefault
            }
          }
        }
      }
    `;
    return this.apollo.watchQuery<Query>({
      variables: { filter: params },
      query: GET_CATEGORY,
      fetchPolicy: 'no-cache',
    }).valueChanges;
  }

  create(data: CommonCategoryPayload) {
    const CREATE_CATEGORY = gql`
      mutation ($payload: CommonCategoryPayload!) {
        addCommonCategory(payload: $payload) {
          code
        }
      }
    `;
    return this.apollo.mutate<Mutation>({
      variables: { payload: data },
      mutation: CREATE_CATEGORY,
    });
  }

  update(data: CommonCategoryPayload) {
    const UPDATE_CATEGORY = gql`
      mutation ($payload: CommonCategoryPayload!) {
        updateCommonCategory(payload: $payload) {
          code
        }
      }
    `;
    return this.apollo.mutate<Mutation>({
      variables: { payload: data },
      mutation: UPDATE_CATEGORY,
    });
  }

  delete(code: string) {
    const QUERY = gql`
      mutation ($code: String!) {
        deleteCommonCategoryByCode(code: $code)
      }
    `;
    return this.apollo.mutate<Mutation>({
      variables: { code: code },
      mutation: QUERY,
    });
  }

  createCommon(code: string, data: CreateCommonPayload) {
    const document = gql`
      mutation ($commonCategoryCode: String!, $payload: CreateCommonPayload!) {
        addCommon(commonCategoryCode: $commonCategoryCode, payload: $payload) {
          code
        }
      }
    `;
    return this.apollo.mutate<Mutation>({
      variables: { payload: data, commonCategoryCode: code },
      mutation: document,
    });
  }

  updateCommon(code: string, data: UpdateCommonPayload) {
    const document = gql`
      mutation ($commonCategoryCode: String!, $payload: UpdateCommonPayload!) {
        updateCommon(commonCategoryCode: $commonCategoryCode, payload: $payload) {
          code
        }
      }
    `;
    return this.apollo.mutate<Mutation>({
      variables: { payload: data, commonCategoryCode: code },
      mutation: document,
    });
  }

  deleteCommon(code: string, commonCategoryCode: string) {
    const document = gql`
      mutation ($commonCategoryCode: String!, $code: String!) {
        deleteCommon(commonCategoryCode: $commonCategoryCode, code: $code)
      }
    `;
    return this.apollo.mutate<Mutation>({
      variables: { code, commonCategoryCode },
      mutation: document,
    });
  }

  private mapQueryByParam(params: ParamDropList) {
    let query = '';
    let variables: string[] = [];
    if (params.identityPaper) {
      query += QUERY_BY_CATEGORY.PaperType;
    }
    if (params.maritalStatus) {
      query += QUERY_BY_CATEGORY.MaritalStatus;
    }
    if (params.relationshipType) {
      query += QUERY_BY_CATEGORY.RelationshipType;
    }
    if (params.merchantType) {
      query += QUERY_BY_CATEGORY.MerchantType;
    }
    if (params.merchantLevel) {
      query += QUERY_BY_CATEGORY.MerchantLevel;
    }

    if (params.occupation) {
      query += QUERY_BY_CATEGORY.Occupation;
    }
    if (params.countries) {
      query += QUERY_BY_CATEGORY.Countries;
    }

    if (params.activityType) {
      query += QUERY_BY_CATEGORY.ActivityType;
    }

    if (params.productAttribute) {
      query += QUERY_BY_CATEGORY.ProductAttribute;
    }

    if (params.activityResult) {
      query += QUERY_BY_CATEGORY.ActivityResult;
    }

    if (_.size(params.categoryCodes) > 0) {
      query += QUERY_BY_CATEGORY.CategoryCodes;
      variables.push('$categoryCodes: [String]!');
    }
    if (params.countryCode) {
      query += QUERY_BY_CATEGORY.ProvincesByCountryCode;
      variables.push('$countryCode: String!');
    }
    if (params.provinceCode) {
      query += QUERY_BY_CATEGORY.DistrictsByProvinceCode;
      variables.push('$provinceCode: String!');
    }
    if (params.districtCode) {
      query += QUERY_BY_CATEGORY.WardsByDistrictCode;
      variables.push('$districtCode: String!');
    }
    if (params.roles) {
      query += QUERY_BY_CATEGORY.Roles;
    }

    if (variables.length > 0) {
      query = `query(${variables.join(',')}) { ${query} }`;
    } else {
      query = `query { ${query} }`;
    }
    return query;
  }

  private mapDataModelDropList(data: Query, params: ParamDropList) {
    let dropList: DropList = {};
    if (data.countries) {
      dropList.countries = <Country[]>data.countries;
    }
    if (data.provincesByCountryCode) {
      dropList.provinces = <Province[]>data.provincesByCountryCode;
    }
    if (data.districtsByProvinceCode) {
      dropList.districts = <District[]>data.districtsByProvinceCode;
    }
    if (data.wardsByDistrictCode) {
      dropList.wards = <Ward[]>data.wardsByDistrictCode;
    }
    if (data.identityPapers) {
      dropList.identityPapers = <IdentityPaper[]>data.identityPapers;
    }

    if (data.productAttributes) {
      dropList.productAttribute = <ProductAttribute[]>data.productAttributes;
    }
    if (data.commonCategoriesByCodes) {
      data.commonCategoriesByCodes.forEach((common: any) => {
        dropList[_.lowerFirst(Object.keys(CategoryCode)[Object.values(CategoryCode).indexOf(common?.code)])] =
          common?.values.sort(
            (firstItem: Common, secondItem: Common) => firstItem.orderNumber - secondItem.orderNumber
          );
      });
    }
    if (data.relationshipTypes) {
      let relationshipDropList: Maybe<RelationshipType>[] = [];
      params.relationshipType?.forEach((applyTo: RelationshipTypeApplyTo) => {
        relationshipDropList = [
          ...(relationshipDropList || []),
          ...(data.relationshipTypes?.filter((e: Maybe<RelationshipType>) => {
            return e?.applyTo?.includes(applyTo);
          }) || []),
        ];
      });
      dropList.relationshipTypes = <RelationshipType[]>relationshipDropList;
    }
    if (data.maritalStatuses) {
      dropList.maritalStatuses = <MaritalStatus[]>data.maritalStatuses;
    }

    if (data.activityTypes) {
      dropList.activityType = <ActivityType[]>data.activityTypes;
    }

    if (data.activityResults) {
      dropList.activityResult = <ActivityResult[]>data.activityResults;
    }

    if (data.merchantTypes) {
      dropList.merchantTypes = <MerchantType[]>data.merchantTypes;
    }

    if (data.occupations) {
      dropList.occupation = <Occupation[]>data.occupations;
    }
    if (data.merchantLevels) {
      dropList.merchantLevels = <MerchantLevel[]>data.merchantLevels;
    }
    if (data.roles) {
      dropList.roles = <Role[]>data.roles;
    }
    return dropList;
  }
}
