export const REFRESH_BREACRUMB = 'REFRESH_BREACRUMB';

export const STATUS_DROPLIST = [
  { code: true, name: 'common.active' },
  { code: false, name: 'common.inactive' },
];

export const REGEX_CONSTANT = {
  alphabetAndNumeric: /^[a-zA-Z0-9]*$/,
  alphabetNumericSpace: /^[a-zA-Z0-9 ]*$/,
  domain:
    /^(?:(?:(?:[a-zA-z\-]+)\:\/{1,3})?(?:[a-zA-Z0-9])(?:[a-zA-Z0-9\-\.]){1,61}(?:\.[a-zA-Z]{2,})+|\[(?:(?:(?:[a-fA-F0-9]){1,4})(?::(?:[a-fA-F0-9]){1,4}){7}|::1|::)\]|(?:(?:[0-9]{1,3})(?:\.[0-9]{1,3}){3}))(?:\:[0-9]{1,5})?$/,
  blockSpecialCharacter: /^[^~`!@#$%^&()_={}[\]:;,.<>+\/?-]*$/,
  onlyNumber: /^\d*$/,
  codeFormat: /^[A-Z0-9_]+$/,
  timeFormat: /^([0-1]?\d|2[0-3]):[0-5]\d$/,
  internationalPhoneFormat: /^\+?\d{1,16}$/,
  email: /[^.][\w.\-][^<>/:;,+=@]+@[a-zA-Z]{2,}[.][a-zA-Z]{2,}/,
};

export const SERVER_DATE_FORMAT = 'YYYY-MM-DD';
export const SERVER_DATE_FORMAT_2 = 'YYYY/MM/DD';
export const DATE_FORMAT = 'DD/MM/YYYY';
export const DATE_DAY = 'DD';
export const DATE_MONTH = 'MM';
export const DATE_YEAR = 'YYYY';
export const DATE_MONTH_YEAR = 'MM/YYYY';
export const DATE_TIME_FORMAT = 'DD/MM/YYYY HH:mm:ss';
export const TIME_FORMAT = 'HH:mm';
//Dùng cho multilanguage
export const VALIDATORS_KEY: any = {
  required: 'validateError.required',
  pattern: 'validateError.pattern',
  minTimePresent: 'validateError.minTimePresent',
  minlength: 'validateError.minlength',
  maxlength: 'validateError.maxlength',
  max: 'validateError.max',
  min: 'validateError.min',
  email: 'validateError.email',
  datePattern: 'validateError.datePattern',
  minDateAndMaxDate: 'validateError.minDateAndMaxDate',
  minDate: 'validateError.minDate',
  maxDate: 'validateError.maxDate',
  phonePattern: 'validateError.phonePattern',
  maxDateToday: 'validateError.maxDateToday',
  minDateToday: 'validateError.minDateToday',
  minAtTheMoment: 'validateError.minAtTheMoment',
  requiredLength: 'validateError.requiredLength',
  maxDateFromNow: 'validateError.maxDateFromNow',
};

export const EMAIL_PATTERN = '[^.][a-zA-Z0-9.-_][^<>/:;,+=@]{1,}@[a-zA-Z]{2,}[.][a-zA-Z]{2,}';
export const COUNTRY_CODE = 'VN';
