import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Message } from 'primeng/api';
import { Observable, Subject, Subscription } from 'rxjs';
import { NotifyMessageType } from '../utils/enums';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class NotificationMessageService implements OnDestroy {
  subjectMessage = new Subject<Message>();
  subjectDialog = new Subject<any>();
  options: Message = {};

  constructor(private http1: HttpClient, private translate: TranslateService) {
    this.options = {
      life: 5000,
    };
  }

  success(messageKey: string, isDialog?: boolean) {
    if (!isDialog) {
      this.show({ severity: NotifyMessageType.Success, detail: this.translate.instant(messageKey), ...this.options });
    }
  }

  error(messageKey: string, isDialog?: boolean) {
    if (!isDialog) {
      this.show({ severity: NotifyMessageType.Error, detail: messageKey, ...this.options });
    }
  }

  info(messageKey: string, isDialog?: boolean) {
    if (!isDialog) {
      this.show({ severity: NotifyMessageType.Info, detail: messageKey, ...this.options });
    }
  }

  warn(messageKey: string, isDialog?: boolean) {
    if (!isDialog) {
      this.show({ severity: NotifyMessageType.Warn, detail: messageKey, ...this.options });
    }
  }

  confirm(message: string): Observable<any> {
    this.subjectDialog.next({ key: 'confirm', message, title: 'dialog.titleConfirm' });
    return new Observable<any>(observer => {
      const sub: Subscription = this.subjectDialog.subscribe(res => {
        sub.unsubscribe();
        return observer.next(res.key === 'accept');
      });
    });
  }

  confirmApproved(): Observable<any> {
    this.subjectDialog.next({ key: 'confirmApproved' });
    return new Observable<any>(observer => {
      const sub: Subscription = this.subjectDialog.subscribe(res => {
        sub.unsubscribe();
        return observer.next({ isConfirm: res.key === 'accept', data: res.data });
      });
    });
  }

  confirmReject(): Observable<any> {
    this.subjectDialog.next({ key: 'confirmReject' });
    return new Observable<any>(observer => {
      const sub: Subscription = this.subjectDialog.subscribe(res => {
        sub.unsubscribe();
        return observer.next({ isConfirm: res.key === 'accept', data: res.data });
      });
    });
  }

  show(notify: Message) {
    if (notify.detail) {
      notify.detail = this.translate.instant(notify.detail);
    }
    this.subjectMessage.next(notify);
  }

  ngOnDestroy(): void {
    this.subjectDialog.unsubscribe();
    this.subjectMessage.unsubscribe();
  }
}
