<div class="p-dialog-header">
  <span class="p-dialog-title">{{ data.title | translate }}</span>
  <div class="p-dialog-header-icons">
    <button (click)="cancel()" class="p-dialog-header-icon p-dialog-header-maximize p-link" type="button">
      <span class="p-dialog-header-close-icon pi pi-times"></span>
    </button>
  </div>
</div>
<div class="p-dialog-content">
  <span class="p-confirm-dialog-message"> {{ data.message | translate }}? </span>
  <div class="footer">
    <button
      (click)="confirm(true)"
      icon="pi pi-check"
      class="p-button-outlined"
      label="{{ 'button.yes' | translate }}"
      pButton></button>

    <button
      (click)="confirm(false)"
      class="p-button-cancel p-button-outlined"
      icon="pi pi-times"
      label="{{ 'button.no' | translate }}"
      pButton></button>
  </div>
</div>
