import {Component, Input} from '@angular/core';

// @ts-ignore
@Component({
  selector: 'lp-progress-bar',
  templateUrl: './lp-progress-bar.component.html',
  styleUrls: ['./lp-progress-bar.component.scss'],
})
export class LPProgressBarComponent {
  @Input() value = 0;
  @Input() mainColor = '';
}
