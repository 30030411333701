import { AfterViewInit, Component, ElementRef, forwardRef, Input, Output, EventEmitter } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators,
} from '@angular/forms';
import { getErrorMessage } from '@cores/utils/functions';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'lp-input-radio-button',
  templateUrl: './lp-input-radio-button.component.html',
  styleUrls: ['./lp-input-radio-button.component.scss'],
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => LPInputRadioButtonComponent),
      multi: true,
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LPInputRadioButtonComponent),
      multi: true,
    },
  ],
})
export class LPInputRadioButtonComponent implements Validator, ControlValueAccessor, AfterViewInit {
  @Input() label: string = 'EMPTY';
  @Input() placeholder: string = 'EMPTY';
  @Input() showLabel: boolean = true;
  @Input() options: any;
  @Input() readonly: boolean = false;
  @Input() border: boolean = false;
  @Output() doChange = new EventEmitter<any>();
  initComp: boolean = false;

  absControl!: AbstractControl;
  control = new FormControl();

  constructor(private el: ElementRef<HTMLElement>, private translate: TranslateService) {
    this.control.valueChanges.subscribe(value => {
      if (this.onChange) {
        this.onChange(value);
      }
    });
  }

  get errors() {
    return (
      (this.el.nativeElement.closest('.ng-submitted') || this.absControl?.touched || this.absControl?.dirty) &&
      this.absControl?.errors &&
      !this.readonly
    );
  }

  trimData() {
    this.control?.setValue(this.control?.value?.trim() || '');
  }

  ngAfterViewInit() {
    console.log('init view');
  }

  onChange = (value: string) => {};

  onTouched = () => {};

  //Lấy ra message lỗi validate để hiển thị, nếu có nhiều lỗi -> hiển thị lỗi đầu tiên.
  getError() {
    let errorKey = Object.keys(this.absControl.errors as object)[0];
    let errorValue: any = this.absControl.errors![errorKey];
    return getErrorMessage(errorKey, errorValue, this.translate.instant(this.label));
  }

  //Dùng để check trường hiện tại có phải required hay không.
  checkRequire() {
    return this.absControl?.hasValidator(Validators.required);
  }

  writeValue(value: string): void {
    this.control.setValue(value, { emitEvent: false });
    if (this.absControl) {
      this.absControl.markAsPristine();
    }
    this.initComp = true;
  }

  registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    if (isDisabled) {
      this.control.disable({ emitEvent: false });
    } else {
      this.control.enable();
    }
  }

  validate(control: AbstractControl): ValidationErrors | null {
    this.absControl = control;
    return null;
  }
  emitChange(event: any) {
    this.doChange.emit(event);
  }
}
