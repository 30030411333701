import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Injector,
  Input,
  Output,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  FormControlName,
  FormGroupDirective,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  NgControl,
  NgForm,
  NgModel,
  ValidationErrors,
  Validator,
  Validators,
} from '@angular/forms';
import { getErrorMessage, removeAscent } from '@cores/utils/functions';
import { TranslateService } from '@ngx-translate/core';
import { isNull } from 'lodash';

@Component({
  selector: 'lp-input-text',
  templateUrl: './lp-input-text.component.html',
  styleUrls: ['./lp-input-text.component.scss'],
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => LPInputTextComponent),
      multi: true,
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LPInputTextComponent),
      multi: true,
    },
  ],
})
export class LPInputTextComponent implements Validator, ControlValueAccessor, AfterViewInit {
  @Input() label: string = 'EMPTY';
  @Input() placeholder: string = 'EMPTY';
  @Input() showLabel: boolean = true;
  @Input() showGroup: boolean = false;
  @Input() groupContent: string = '';
  @Input() groupContentPosition: 'left' | 'right' = 'right'; // "left" | "right"
  @Input() groupContentPrefix: string = '';
  @Input() groupPlaceholder: string = '';
  @Input() pattern: string = '';
  @Input() searchIcon = false;
  @Input() needRemoveAscent = false;
  @Output() clickSearchEvent = new EventEmitter();
  @Input() required?: boolean | string;
  @Input() readonly: boolean = false;
  @Input() disabled: boolean = false;
  @Input() border: boolean = true;
  @Input() type: string = 'text';
  @Input() uppercase: boolean = false;
  hasChanged: boolean = false;
  initComp = false;
  value: any;
  initialValue: any;
  // absControl!: AbstractControl;
  control = new FormControl<any>(null);
  ngControl?: NgControl;

  constructor(
    private el: ElementRef<HTMLElement>,
    private translate: TranslateService,
    private inj: Injector,
    private cdr: ChangeDetectorRef
  ) {
    this.control.valueChanges.subscribe(value => {
      if (this.onChange) {
        if (this.hasChanged) {
          //Check xem đây có phải lần onChange đầu tiên không, nếu là lần đầu thì cần check empty để tránh validate value khởi tạo
          this.onChange(value);
        } else {
          if (value === this.initialValue || isNull(value)) {
            return;
          }
          this.hasChanged = true;
          this.onChange(value);
        }
      }
    });
  }

  ngAfterViewInit() {
    this.ngControl = this.inj.get(NgControl);
    this.cdr.detectChanges();
  }

  get errors() {
    return (
      (((this.ngControl as NgModel | FormControlName)?.formDirective as NgForm | FormGroupDirective)?.submitted ||
        this.ngControl?.touched ||
        this.ngControl?.dirty) &&
      this.ngControl?.errors &&
      !this.readonly
    );
    // return (
    //   (this.el.nativeElement.closest('.ng-submitted') || this.absControl?.touched || this.absControl?.dirty) &&
    //   this.absControl?.errors &&
    //   !this.readonly
    // );
  }

  onModelChange() {
    this.onChange(this.value);
  }

  onInput(event: any) {
    if (this.uppercase) {
      this.control.setValue(event?.target?.value.toUpperCase());
    } else {
      this.control.setValue(event?.target?.value);
    }
    this.onChange(this.control.value);
  }

  trimData() {
    if (this.needRemoveAscent) {
      this.control.setValue(removeAscent(this.control.value).toUpperCase());
    }
    this.control?.setValue(this.control?.value?.trim() || null);
    if (this.searchIcon) {
      this.ngControl?.control?.markAsPristine();
      this.ngControl?.control?.markAsUntouched();
    }
  }

  checkControlValue() {
    return Array.isArray(this.control.value);
  }

  clickSearch() {
    this.clickSearchEvent.emit();
  }

  onChange = (_value: string) => {};

  onTouched = () => {};

  //Lấy ra message lỗi validate để hiển thị, nếu có nhiều lỗi -> hiển thị lỗi đầu tiên.
  getError() {
    let errorKey = Object.keys(this.ngControl?.errors as object)[0];
    let errorValue: any = this.ngControl?.errors![errorKey];
    return getErrorMessage(errorKey, errorValue, this.translate.instant(this.label));
  }

  //Dùng để check trường hiện tại có phải required hay không.
  checkRequire() {
    return this.ngControl?.control?.hasValidator(Validators.required);
  }

  writeValue(value: string): void {
    this.initialValue = value;
    this.control.setValue(value);
    this.initComp = true;
    if (this.ngControl) {
      this.ngControl?.control?.markAsPristine();
    }
  }

  registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    if (isDisabled) {
      this.control.disable({ emitEvent: false });
    } else {
      this.control.enable({ emitEvent: false });
    }
  }

  validate(control: AbstractControl): ValidationErrors | null {
    // this.absControl = control;
    return null;
  }
}
