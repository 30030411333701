/* eslint-disable prettier/prettier */
export enum NotifyMessageType {
  Success = 'success',
  Error = 'error',
  Info = 'info',
  Warn = 'warn',
}

export enum SessionKey {
  UserProfile = 'KEY_USER_PROFILE',
  Preferences = 'PREFERENCES',
  Icon = 'Icon',
}

export enum CategoryCode {
  MaritalStatus = 'MaritalStatus',
  PaperType = 'PaperType',
  RelationshipType = 'RelationshipType',
  MerchantType = 'MerchantType',
  CampaignType = 'CAMPAIGN_TYPE',
  CampaignStatus = 'CAMPAIGN_STATUS',
  GroupAccount = 'GROUP_ACCOUNT',
  SourceAccount = 'SOURCE_ACCOUNT',
  Gender = 'GENDER',
  CampaignForm = 'CAMPAIGN_FORM',
  ProductType = 'PRODUCT_TYPE',
  ProductUnit = 'PRODUCT_UNIT',
  Status = 'STATUS',
  ActivityStatus = 'ACTIVITY_STATUS',
  ActivityPriority = 'ACTIVITY_PRIORITY',
  OpportunityStatus = 'OPP_STATUS',
  SaleStatus = 'SALE_STATUS',
  UserStatus = 'USER_STATUS',
  AddonType = 'ADDON_TYPE',
  AddonValueAge = 'ADDON_VALUE_AGE',
  AddonValueType = 'ADDON_VALUE_TYPE',
  AddonAddress = 'ADDON_ADDRESS',
  CampaignFileType = 'CAMPAIGN_FILE_TYPE',
  SourceActivity = 'SOURCE_ACTIVITY',
  HistoryAssign = 'TYPE_HISTORY_ASSIGN',
}

export enum ScreenType {
  Create,
  Update,
  Detail,
}

export enum ActivityType {
  CALL = 'CALL',
  MEETING = 'MEETING',
  SMS = 'SMS',
  EMAIL = 'EMAIL',
}

export enum Action {
  Update = 'UPDATE',
  Create = 'CREATE',
  Export = 'EXPORT',
  Import = 'IMPORT',
  Delete = 'DELETE',
  View = 'VIEW',
  Active = 'ACTIVE',
  Stop = 'STOP',
  Call = 'CALL',
  Sms = 'SMS',
  Email = 'EMAIL',
  TransferUser = 'TRANSFER',
  LockUser = 'LOCK_USER',
  UnlockUser = 'UNLOCK_USER',
  TransferCrossSell = 'TRANSFER_CROSS_SELL',
  ConvertLeadToOpp = 'CONVERT_LEAD_TO_OPP',
  ConvertOppToSale = 'CONVERT_OPP_TO_SALE',
  Assign = 'ASSIGN',
  CloseSales = 'CLOSE_SALES',
}

export enum ColumnDataType {
  Text = 'text',
  Date = 'date',
  Currency = 'currency',
  File = 'file',
  Number = 'number',
  Action = 'action',
  Array = 'array',
}

export enum FunctionCode {
  Dashboard = 'DASHBOARD_MODULE',
  Category = 'CATEGORY',
  CreateUser = 'CREATE_USER',
  UserManagement = 'USER-MANAGEMENT',
  FunctionManagement = 'FUNCTION-MANAGEMENT',
  Profile = 'PROFILE_MODULE',
  Campaign = 'CAMPAIGN_MODULE',
  CreateCampaign = 'CREATE_CAMPAIGN',
  UpdateCampaign = 'UPDATE_CAMPAIGN',
  MerchantManagement = 'MERCHANT_MANAGEMENT',
  CustomerInformation = 'CUSTOMER_INFORMATION',
  Customer = 'CUSTOMER_MODULE',
  Calendar = 'CALENDAR',
  Product = 'PRODUCT',
  Domain = 'DOMAIN',
  Inventory = 'INVENTORY_MODULE',
  UpdateInventory = 'UPDATE_INVENTORY',
  Sales = 'SALES',
}

export enum StatusStep {
  Done = 'DONE',
  InProgress = 'INPROGRESS',
  Failed = 'FAILED',
  Skipped = 'SKIPPED',
  Pending = 'PENDING',
  Next = 'NEXT',
}

export enum StatusStepCode {
  CampaignInformationCode = 'RECRUIT_APPLICATION_STATUS_01',
  TemplateCode = 'RECRUIT_APPLICATION_STATUS_02',
  ChoosePersonAndCalendarCode = 'RECRUIT_APPLICATION_STATUS_03',
}

export enum CustomerGroupCode {
  CustomerActive = 'GROUP_ACCOUNT_02',
  CustomerPotential = 'GROUP_ACCOUNT_01',
}

export enum CustomerGroupName {
  CustomerActive = 'Khách hàng hiện hữu',
  CustomerPotential = 'Khách hàng tiềm năng',
}

export enum DocumentCategory {
  IdentityPaper = 'IDENTITY_PAPER',
  Certificate = 'CERTIFICATE',
  BusinessRegistration = 'BUSINESS_REGISTRATION',
}

export enum StatusCodeUpdateInventory {
  ImportInventory = 1,
  ExportInventory = 2,
}

export enum TypeHistoryAssign {
  Assignment = 'ASSIGNMENT',
  Referrer = 'REFERRER',
}
