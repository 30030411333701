<div (mouseenter)="mouseEnterMenu()" (mouseleave)="mouseLeaveMenu()" [class]="classMenu">
  <div class="sidebar-logo">
    <!--    <a (click)="$event.preventDefault(); onStaticMenu()" class="sidebar-pin" href="#" title="Toggle Menu">-->
    <!--      <em [class]="isLock ? 'pi pi-lock' : 'pi pi-lock-open'"></em>-->
    <!--    </a>-->
    <a routerlink="/">
      <img [src]="pathImage" alt="logo" />
    </a>
  </div>
  <div class="separator"></div>
  <div class="layout-menu-container">
    <ul class="layout-menu">
      <ng-container *ngFor="let item of items">
        <li *ngIf="checkRole(item)" [class.active-menuitem]="item.active">
          <ng-container [ngTemplateOutletContext]="{ itemMenu: item }"
            [ngTemplateOutlet]="item.children && item.children.length > 0 ? sub : noSub">
          </ng-container>
        </li>
      </ng-container>
    </ul>
  </div>
  <div class="separator"></div>
  <div class="sidebar-profile">
    <div #profile class="profile flex flex-column align-items-center">
      <div class="notify-column">
        <em class="lp-bell"></em>
        <span class="num-noti">{{numNotifi ? numNotifi : 0}}</span>
      </div>
      <div class="flex flex-row align-items-center justify-content-between" style="width: 100%">
        <div (click)="routingProfile()" class="flex align-items-center ">
          <p-avatar image="assets/images/avatar-example.png" shape="circle" size="large"
            styleClass="cursor-pointer"></p-avatar>
          <!--      <p-overlayPanel #op [appendTo]="profile">-->
          <!--        <ng-template pTemplate>-->
          <!--          <div class="account-body">-->
          <!--            <span class="account-name">{{'HuyDT'}}</span>-->
          <!--            <span class="account-username">@{{ 'Huy.duongnhat@lptech.vn' }}</ span>-->
          <!--            <a (click)="routingProfile()" class="account-text">{{ 'user-profile.button.userProfile' | translate}}</a>-->
          <!--          </div>-->
          <!--          <div (click)="logout()" class="cursor-pointer">{{ 'button.logout' | translate }}</div>-->
          <!--        </ng-template>-->
          <!--      </p-overlayPanel>-->
          <div class="fullName"> {{(currUser.lastname + " " + currUser.firstname) || '' }}</div>
        </div>
        <div class="notify-row mr-3" (click)=" $event.preventDefault(); showNotifi()">
          <em class="lp-bell"></em>
          <span class="num-noti">{{numNotifi ? numNotifi : 0}}</span>
        </div>
      </div>
    </div>
    <span (click)="logout()" class="logout-btn">{{'button.logout' | translate}}</span>
  </div>
  <app-notifications *ngIf="openNotifi"></app-notifications>
</div>

<ng-template #noSub let-itemMenu="itemMenu">
  <a (click)="$event.preventDefault(); activeMenuItem(itemMenu)"
    routerLink="{{ !itemMenu.routerLink ? undefined : itemMenu.routerLink }}"
    routerLinkActive="active-menuitem-routerlink">
    <span class="menuitem-text">{{ ('layouts.menu.' + itemMenu.label) | lowercase | translate }}</span>
    <em [class]="'layout-menuitem-icon pi pi-fw ' + itemMenu.icon"></em>
  </a>
</ng-template>
<ng-template #sub let-itemMenu="itemMenu">
  <a (click)="$event.preventDefault(); activeMenuItem(itemMenu)">
    <span class="menuitem-text">{{ ('layouts.menu.' + itemMenu.label) | lowercase | translate }}</span>
    <div class="flex align-items-center">
      <em class="pi pi-fw pi-angle-down layout-submenu-toggler"></em>
      <em [class]="'layout-menuitem-icon pi pi-fw ' + itemMenu.icon"></em>
    </div>
  </a>
  <ul *ngIf="itemMenu.active" @toggleMenu>
    <ng-container *ngFor="let item of itemMenu.children">
      <li *ngIf="checkRole(item)" [class.active-menuitem]="item.active">
        <ng-container [ngTemplateOutletContext]="{ itemMenu: item }"
          [ngTemplateOutlet]="item.children?.length > 0 ? sub : noSub">
        </ng-container>
      </li>
    </ng-container>
  </ul>
</ng-template>
