import { Directive, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';

@Directive({
  selector: '[chipView]',
})
export class ChipViewDirective implements OnChanges {
  @Input() value: any;

  constructor(private el: ElementRef, private translateService: TranslateService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (!_.isEqual(changes['value'].currentValue, changes['value'].previousValue)) {
      if (Array.isArray(this.value)) {
        if (!this.value.length) {
          this.el.nativeElement.innerHTML = '---';
        } else {
          let html = _.reduce(
            this.value,
            (result, currValue) => {
              return result + `<span class="chip-view--item">${this.translateService.instant(currValue || ' ')}</span>`;
            },
            ''
          );
          this.el.nativeElement.innerHTML = `<div class="chip-view--container">` + html + `</div>`;
        }
      } else {
        this.el.nativeElement.innerHTML = `${this.translateService.instant(this.value || ' ')}`;
      }
    }
  }
}
