<span *ngIf="readonly" class="readonly-view">
  <div class="readonly-label">{{ label | translate }}</div>
  <div class="readonly-content">{{ (control.value | date: 'dd/MM/yyyy') || '---' }}</div>
</span>
<span *ngIf="!readonly" class="p-float-label">
  <p-calendar
    #calendar
    (keydown.space)="$event.preventDefault()"
    (onBlur)="onTouched(); onBlur($event)"
    (onInput)="onInput($event)"
    [dateFormat]="dateFormat"
    [firstDayOfWeek]="1"
    [formControl]="control"
    [keepInvalid]="true"
    [maxDate]="maxDate"
    [minDate]="minDate"
    [ngClass]="{ 'ng-invalid ng-dirty': errors, readonly: readonly }"
    [readonlyInput]="readonly"
    [selectionMode]="selectionMode"
    [showIcon]="!readonly"
    [showOnFocus]="false"
    [timeOnly]="timeOnly"
    [view]="view"
    icon="pi pi-calendar"
    appCalendarWithMask
    appendTo="body"
  >
  </p-calendar>
  <label class="block">
    <span *ngIf="showLabel"> {{ label | translate }}</span>
    <span *ngIf="checkRequire()" class="p-errors" style="color: red; margin-left: 3px">*</span></label
  >
  <div class="relative b-errors">
    <ng-container *ngIf="errors">
      <small class="p-error error-msg block fadeinup animation-duration-300">
        <span class="block">{{ getError().key | translate: getError().required }}</span>
      </small>
    </ng-container>
  </div>
</span>
