import { EventEmitter, Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import {
  Contact,
  ContactType,
  CreateUserPayload,
  Mutation,
  Query,
  Role,
  TransferUserPayload,
  UpdateUserPayload,
  UserFilter,
  UserStatus,
} from '@generated';
import { removeParamNullOrUndefined } from '@cores/utils/functions';
import { map } from 'rxjs';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class UserManagementService {
  onMeUpdate = new EventEmitter();

  constructor(private apollo: Apollo) {}

  findByUsername(username: string) {
    const GET_USER = gql`
      query ($username: String!) {
        userByUsername(username: $username) {
          firstname
          contacts {
            type
            data
          }
          username
          status
          lastname
          dob
          gender
          roles {
            code
            name
          }
          maritalStatus {
            id
          }
          merchant {
            id
            viName
            enName
            domain
            type {
              name
            }
            level {
              name
            }
          }
          documents {
            identityNumber
            identityPaper{
              id
            }
          }
          address {
            address
            ward {
              code
              district {
                code
                province {
                  code
                }
              }
            }
          }
          relationships {
            phone
            email
            dob
            firstname
            lastname
            type {
              code
            }
            identityPaper {
              id
            }
            identityPaperNumber
          }
        }
      }
    `;
    return this.apollo
      .watchQuery<Query>({
        variables: { username: username },
        query: GET_USER,
        fetchPolicy: 'no-cache',
      })
      .valueChanges.pipe(map(res => res?.data?.userByUsername));
  }

  findUserForTransfer(username: string) {
    const GET_USER = gql`
      query ($username: String!) {
        userByUsername(username: $username) {
          username
          status
          merchant {
            id
            viName
          }
          roles {
            code
          }
        }
      }
    `;
    return this.apollo
      .watchQuery<Query>({
        variables: { username: username },
        query: GET_USER,
        fetchPolicy: 'no-cache',
      })
      .valueChanges.pipe(map(res => res?.data?.userByUsername));
  }

  search(params?: UserFilter) {
    params = removeParamNullOrUndefined(params);
    const GET_USER_LIST = gql`
      query ($filter: UserFilter!) {
        pageUser(filter: $filter) {
          count
          list {
            username
            firstname
            lastname
            status
            dob
            contacts {
              type
              data
            }
            merchant {
              viName
            }
            createdBy
            createdDate
            roles {
              name
            }
          }
        }
      }
    `;
    return this.apollo
      .watchQuery<Query>({
        variables: { filter: params },
        query: GET_USER_LIST,
        fetchPolicy: 'no-cache',
      })
      .valueChanges.pipe(
        map(res => {
          return {
            list: res.data?.pageUser?.list?.map(e => {
              const obj: any = { ...e };
              if (_.find(e?.contacts, (contact: Contact) => contact.type === ContactType.Email)) {
                obj.email = e?.contacts?.find((contact: Contact | null) => contact?.type === ContactType.Email)?.data;
              }
              obj.rolesName = e?.roles?.reduce((prev: string[], current: Role | null) => {
                if (current?.name) {
                  return [...prev, current?.name];
                }
                return [...prev];
              }, []);
              return obj;
            }),
            count: res.data?.pageUser?.count,
          };
        })
      );
  }

  changeUserStatus(username: string = '', status: UserStatus) {
    const CHANGE_USER_STATUS = gql`
      mutation ($username: String!, $status: UserStatus!) {
        changeUserStatus(username: $username, status: $status) {
          status
        }
      }
    `;
    return this.apollo.mutate({
      variables: { username: username, status: status },
      mutation: CHANGE_USER_STATUS,
    });
  }

  transferUser(data: TransferUserPayload) {
    const TRANSFER_USER = gql`
      mutation ($payload: TransferUserPayload!) {
        transferUser(payload: $payload) {
          username
        }
      }
    `;
    return this.apollo.mutate({
      variables: { payload: data },
      mutation: TRANSFER_USER,
    });
  }

  create(data: CreateUserPayload) {
    const CREATE_USER = gql`
      mutation ($payload: CreateUserPayload!) {
        createUser(payload: $payload)
      }
    `;
    return this.apollo.mutate({
      variables: { payload: data },
      mutation: CREATE_USER,
    });
  }

  update(data: UpdateUserPayload) {
    const UPDATE_USER = gql`
      mutation ($payload: UpdateUserPayload!) {
        updateUser(payload: $payload) {
          username
        }
      }
    `;
    return this.apollo.mutate({
      variables: { payload: data },
      mutation: UPDATE_USER,
    });
  }

  delete(username: string) {
    const DELETE_USER = gql`
      mutation ($username: String!) {
        deleteByUsername(username: $username)
      }
    `;
    return this.apollo.mutate<Mutation>({
      variables: { username },
      mutation: DELETE_USER,
    });
  }

  updatePreferences(preferences: any) {
    const UPDATE_PREFERENCES = gql`
      mutation ($preferences: Object!) {
        updateMyPreferences(preferences: $preferences) {
          preferences
        }
      }
    `;
    return this.apollo.mutate({
      variables: { preferences: preferences },
      mutation: UPDATE_PREFERENCES,
    });
  }
}
