<div class="main-page p-3 notications">
  <div class="header-section">
    <div class="header-section-left">
      <h6 class="title-page">{{ 'notifications.notifications' | translate }}
      </h6>
    </div>
    <div class="header-section-right">
      <em class="lp-double-check iconAction bg-success-alternative-dark mr-2"></em>
    </div>
  </div>
  <div class="header-section mb-0">
    <div class="header-section-left">
      <button *ngFor="let
      tab of tabs" label="{{tab?.label}}" [ngClass]="tab?.select ? '' : 'p-button-outlined' " class="btn-tabs" pButton
        (click)="enableBtn(tab.id , tab.select , tab.type)"></button>
    </div>
    <div class="header-section-right btn-filter">
      <em class="lp-filter-noti iconAction bg-primary-main mr-2 "></em>
      <div class="filter-noti">
        <div class="wrap p-2 pl-3 flex flex-column align-content-center justify-content-start">
          <div *ngFor="let item of _listFilter" class="p-field-checkbox filter-noti-item">
            <p-checkbox inputId="{{item?.id}}" [(ngModel)]="item.select" [binary]="true"
              (onChange)="onChangeCheckBox($event , item.id , item?.typeFilter!)"></p-checkbox>
            <label class="pl-2" for="{{item?.id}}">{{item?.label}}</label>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="space-form">
    <div class="space-line"></div>
  </div>
  <div *ngIf="listSelected?.length" class="flex flex-wrap pt-2">
    <div *ngFor="let item of listSelected" class="chose-file mr-2 mb-2">
      <div class="wrap">
        <p class="file-name m-0 pr-1"> {{item?.label}}</p>
        <button (click)="removeItemSelected(item.id , item.typeFilter!)" class="p-0 btn-file-name"
          icon="lp-cancel bg-action-selected" pButton></button>
      </div>
    </div>
  </div>
  <div class="scrollable-content ">
    <div *ngFor="let item of _listNotifications" class="item flex">
      <div class="col-12 p-0 m-0">
        <div class="flex align-items-center">
          <div class="col-1 p-0">
            <button [class]="convertIcon(item?.type).class" [icon]="convertIcon(item?.type).icon" pButton
              style="font-size: 22px"></button>
          </div>
          <div class="col-11 pt-0 pb-0">
            <div class="flex flex-column pt-2 pl-2">
              <div class="row flex justify-content-between">
                <div class="col-11 p-0 flex flex-column justify-content-between" style="min-height: 64px;">
                  <div class="row">
                    <span class="color-span-mess">{{'layouts.menu.customer' | translate}} :</span>
                    <span class="color-span-name"> {{item?.name }}</span>
                    <span class="color-span-mess"> {{item?.mess }}</span>
                  </div>
                  <div class="row flex align-items-center">
                    <!-- <p class="p-0 m-0 color-span-mess">12 phút trước</p> -->
                    <em class="lp-dots iconAction bg-secondary pl-2 pr-2"></em>
                    <p class="p-0 m-0 color-span-mess">{{item?.from}}</p>
                  </div>
                </div>
                <div class="col-1 p-0">
                  <em class="lp-dots iconAction bg-primary-main"></em>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
