// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  keycloak: {
    issuer: 'https://dev-keycloak21.lptech.vn',
    realm: 'devcrm',
    client_id: 'frontend',
  },
  client_code: 'LPTECH',
  graphql_url: 'https://dev-crm-api.lptech.vn/graphql',
  endpoint_url: 'https://dev-crm-api.lptech.vn',
  apmServerUrl: 'https://dev-apm.lptech.vn',
  firebaseConfig: {
    apiKey: 'AIzaSyCFfV0mfygdkM326MIuH3WiMPQEv8WEEpw',
    authDomain: 'lpt-crm.firebaseapp.com',
    databaseURL: 'https://lpt-crm-default-rtdb.firebaseio.com',
    projectId: 'lpt-crm',
    storageBucket: 'lpt-crm.appspot.com',
    messagingSenderId: '368498317421',
    appId: '1:368498317421:web:0ff9f886f6b2d078730764',
    measurementId: 'G-D4T1M3822H',
    vapidKey: 'BKF1U6vMbhzrbXdiE_dYCempJH-fvRJrFdY835SrXQ3GBjz87H4ByghpwBk7Dwh1mFLFzBZ8y64_QEYikOdZMz4',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
