import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { CreateMerchantPayload, MerchantFilter, Mutation, UpdateMerchantPayload } from '@generated';
import { removeParamNullOrUndefined } from '@cores/utils/functions';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MerchantManagementService {
  constructor(private apollo: Apollo) {}

  findById(id: number) {
    const GET_USER = gql`
      query ($id: PositiveInt!) {
        merchantById(id: $id) {
          id
          domain
          enName
          viName
          active
          level {
            code
            name
          }
          dob
          numberOfEmployee
          businessRegistrationNumber
          type {
            code
            name
          }
          addresses {
            address
            ward {
              name
              district {
                name
                province {
                  name
                }
              }
            }
          }
          contacts {
            type
            data
          }
          parent {
            viName
          }
          relationships {
            firstname
            lastname
            dob
            type {
              name
            }
            phone
            email
            identityPaper {
              name
            }
            identityPaperNumber
          }
        }
      }
    `;
    return this.apollo
      .watchQuery<any>({
        variables: { id: id },
        query: GET_USER,
        fetchPolicy: 'no-cache',
      })
      .valueChanges.pipe(map(res => res?.data?.merchantById));
  }

  search(params?: MerchantFilter) {
    params = removeParamNullOrUndefined(params);
    const GET_MERCHANT = gql`
      query ($filter: MerchantFilter!) {
        pageMerchant(filter: $filter) {
          count
          list {
            id
            viName
            numberOfEmployee
            enName
            active
            type {
              name
            }
            parent {
              viName
            }
            domain
            level {
              name
            }
          }
        }
      }
    `;
    return this.apollo.watchQuery<any>({
      variables: { filter: params },
      query: GET_MERCHANT,
      fetchPolicy: 'no-cache',
    }).valueChanges;
  }

  getAllMerchant(params?: MerchantFilter) {
    params = removeParamNullOrUndefined(params);
    params!.limit = 1000;
    const GET_MERCHANT = gql`
      query ($filter: MerchantFilter!) {
        pageMerchant(filter: $filter) {
          list {
            id
            viName
            numberOfEmployee
            active
            domain
            type {
              name
            }
            level {
              name
            }
          }
        }
      }
    `;
    return this.apollo.watchQuery<any>({
      variables: { filter: params },
      query: GET_MERCHANT,
      fetchPolicy: 'no-cache',
    }).valueChanges;
  }

  getMerchantByParentId(id: number) {
    const GET_MERCHANT = gql`
      query ($id: Int) {
        merchantsByParentId(id: $id) {
          id
          viName
          active
          domain
          type {
            name
          }
        }
      }
    `;
    return this.apollo
      .watchQuery<any>({
        variables: { id: id },
        query: GET_MERCHANT,
        fetchPolicy: 'no-cache',
      })
      .valueChanges.pipe(map(value => value.data.merchantsByParentId));
  }

  create(data: CreateMerchantPayload) {
    const CREATE_MERCHANT = gql`
      mutation ($payload: CreateMerchantPayload!) {
        addMerchant(payload: $payload) {
          viName
          type {
            code
          }
          level {
            code
          }
        }
      }
    `;
    return this.apollo.mutate({
      variables: { payload: data },
      mutation: CREATE_MERCHANT,
    });
  }

  update(data: UpdateMerchantPayload) {
    const UPDATE_MERCHANT = gql`
      mutation ($payload: UpdateMerchantPayload!) {
        updateMerchant(payload: $payload) {
          id
        }
      }
    `;
    return this.apollo.mutate({
      variables: { payload: data },
      mutation: UPDATE_MERCHANT,
    });
  }

  delete(id: number) {
    const QUERY = gql`
      mutation ($id: PositiveInt!) {
        deleteMerchantById(id: $id)
      }
    `;
    return this.apollo.mutate<Mutation>({
      variables: { id: id },
      mutation: QUERY,
    });
  }
}
