import { Component, ElementRef, forwardRef, Input } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators,
} from '@angular/forms';
import { getErrorMessage } from '@cores/utils/functions';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'lp-input-number',
  templateUrl: './lp-input-number.component.html',
  styleUrls: ['./lp-input-number.component.scss'],
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => LPInputNumberComponent),
      multi: true,
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LPInputNumberComponent),
      multi: true,
    },
  ],
})
export class LPInputNumberComponent implements Validator, ControlValueAccessor {
  @Input() label: string = 'EMPTY';
  @Input() showLabel: boolean = true;
  @Input() pattern: string = '';
  @Input() required?: boolean | string;
  @Input() readonly: boolean = false;
  @Input() disabled: boolean = false;
  @Input() border: boolean = true;
  @Input() useGrouping: boolean = true;
  @Input() suffix: string = '';
  @Input() prefix: string = '';
  @Input() maxLength: number = 0;
  @Input() checkLength: boolean = false;

  absControl!: AbstractControl;
  control = new FormControl<any>(null);

  initComp: boolean = false;

  constructor(private el: ElementRef<HTMLElement>, private translate: TranslateService) {
    this.control.valueChanges.subscribe(value => {
      if (this.onChange) {
        this.onChange(value);
      }
    });
  }

  _minFractionDigits: number = 0;

  get minFractionDigits() {
    return this._minFractionDigits;
  }

  @Input() set minFractionDigits(value: number) {
    this._minFractionDigits = value;
    if (this._maxFractionDigits < this._minFractionDigits) {
      this._maxFractionDigits = this._minFractionDigits;
    }
  }

  _maxFractionDigits: number = 0;

  get maxFractionDigits() {
    return this._maxFractionDigits;
  }

  @Input() set maxFractionDigits(value: number) {
    this._maxFractionDigits = value;
    if (this._maxFractionDigits < this._minFractionDigits) {
      this._maxFractionDigits = this._minFractionDigits;
    }
  }

  get errors() {
    return (
      (this.el.nativeElement.closest('.ng-submitted') || this.absControl?.touched || this.absControl?.dirty) &&
      this.absControl?.errors &&
      !this.readonly
    );
  }

  onInput(event: any) {
    this.absControl?.patchValue(event.value);
    this.onChange(event.value);
  }

  onChange = (value: number) => {};

  onTouched = () => {};

  //Lấy ra message lỗi validate để hiển thị, nếu có nhiều lỗi -> hiển thị lỗi đầu tiên.
  getError() {
    let errorKey = Object.keys(this.absControl.errors as object)[0];
    let errorValue: any = this.absControl.errors![errorKey];
    return getErrorMessage(errorKey, errorValue, this.translate.instant(this.label));
  }

  //Dùng để check trường hiện tại có phải required hay không.
  checkRequire() {
    return this.absControl?.hasValidator(Validators.required);
  }

  writeValue(value: any): void {
    value = typeof value === 'number' ? value : null;
    if (value !== this.control.value) {
      this.control.setValue(value, { emitEvent: false });
      if (this.absControl) {
        this.absControl.markAsPristine();
      }
    }
  }

  registerOnChange(fn: (value: number) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    if (isDisabled) {
      this.control.disable({ emitEvent: false });
    } else {
      this.control.enable({ emitEvent: false });
    }
  }

  validate(control: AbstractControl): ValidationErrors | null {
    this.absControl = control;
    let value = control.value;
    if (this.checkLength && value.toString().length > this.maxLength) {
      return {
        maxlength: {
          requiredLength: this.maxLength,
        },
      };
    }
    return null;
  }
}
