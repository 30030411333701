<div class="lpb-modal" *ngIf="show" [ngStyle]="{'z-index':(modalType === 'SEARCH') ? 100 : 10 }">
    <div class="modal-content" [style.width]='widthContent + unit' [style.height]='heightContent + unit'>
        <div class="header">
            <div class="title">{{headerText}}</div>
            <em class="pi pi-times" (click)="hide()"></em>
        </div>
        <div class="info">
            <ng-content></ng-content>
        </div>
    </div>
</div>