import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NotFoundComponent } from '@shared/layouts/not-found/not-found.component';
import { GuardService } from '@cores/services/guard.service';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('@features/features.module').then(m => m.FeaturesModule),
    canActivate: [GuardService],
  },
  {
    path: 'notfound',
    component: NotFoundComponent,
    // canActivate: [GuardService],
  },
  // {
  //   path: 'not-allowed',
  //   loadChildren: () =>
  //     import('@shared/components/account-deactivated/account-deactivated.module').then(
  //       m => m.AccountDeactivatedModule
  //     ),
  //   // canActivate: [DeactivateGuardService],
  // },
  { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
  { path: '**', redirectTo: '/notfound', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
