import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

interface ArgumentPipe {
  listOption?: any[];
  key?: string;
  value?: string;
}

// GET LABEL FROM DROPDOWN DATA

@Pipe({
  name: 'getLabel',
})
export class GetLabelPipe implements PipeTransform {
  transform(value: any, arg: ArgumentPipe): any {
    if (!arg || value === undefined || value === null) {
      return '---';
    }
    const key: string = arg.key || 'code';
    const label: string = arg.value || 'name';
    const item = arg.listOption?.find((e: any) => e[key] === value);
    return _.get(item, label) || '---';
  }
}
