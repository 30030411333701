import { gql } from 'apollo-angular';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** An RFC-3339 compliant Full Date Scalar */
  Date: any;
  DateTime: any;
  /** A JSON scalar */
  JSON: any;
  /** A 64-bit signed integer */
  Long: any;
  /** An Int scalar that must be greater than or equal to zero */
  NonNegativeInt: any;
  /** An object scalar */
  Object: any;
  /** An Int scalar that must be a positive value */
  PositiveInt: any;
};

export type Account = {
  __typename?: 'Account';
  addresses?: Maybe<Array<Maybe<Address>>>;
  code?: Maybe<Scalars['String']>;
  contacts?: Maybe<Array<Maybe<Contact>>>;
  dob?: Maybe<Scalars['Date']>;
  documents?: Maybe<Array<Maybe<Document>>>;
  firstname?: Maybe<Scalars['String']>;
  gender?: Maybe<Gender>;
  lastname?: Maybe<Scalars['String']>;
  maritalStatus?: Maybe<MaritalStatus>;
  merchant?: Maybe<Merchant>;
  occupation?: Maybe<Occupation>;
  relationships?: Maybe<Array<Maybe<Relationship>>>;
  sales?: Maybe<Array<Maybe<Sale>>>;
};

export type AccountFilter = {
  code?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  firstname?: InputMaybe<Scalars['String']>;
  lastname?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['PositiveInt']>;
  offset?: InputMaybe<Scalars['NonNegativeInt']>;
  phone?: InputMaybe<Scalars['String']>;
  search?: InputMaybe<Scalars['String']>;
};

export type Activity = {
  __typename?: 'Activity';
  account?: Maybe<Account>;
  actualEnd?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['PositiveInt']>;
  lead?: Maybe<Lead>;
  opportunities?: Maybe<Array<Maybe<Opportunity>>>;
  priority?: Maybe<ActivityPriority>;
  result?: Maybe<ActivityResult>;
  sales?: Maybe<Array<Maybe<Sale>>>;
  scheduleEnd?: Maybe<Scalars['DateTime']>;
  scheduleStart?: Maybe<Scalars['DateTime']>;
  source?: Maybe<ActivitySource>;
  status?: Maybe<ActivityStatus>;
  type?: Maybe<ActivityType>;
};

export type ActivityDashboard = {
  __typename?: 'ActivityDashboard';
  callActivityIdsByLead?: Maybe<Scalars['Object']>;
  callActivityIdsByOpp?: Maybe<Scalars['Object']>;
  callActivityIdsBySale?: Maybe<Scalars['Object']>;
  emailActivityIdsByLead?: Maybe<Scalars['Object']>;
  emailActivityIdsByOpp?: Maybe<Scalars['Object']>;
  emailActivityIdsBySale?: Maybe<Scalars['Object']>;
  meetingActivityIdsByLead?: Maybe<Scalars['Object']>;
  meetingActivityIdsByOpp?: Maybe<Scalars['Object']>;
  meetingActivityIdsBySale?: Maybe<Scalars['Object']>;
  smsActivityIdsByLead?: Maybe<Scalars['Object']>;
  smsActivityIdsByOpp?: Maybe<Scalars['Object']>;
  smsActivityIdsBySale?: Maybe<Scalars['Object']>;
};

export type ActivityFilter = {
  actualEnd?: InputMaybe<Scalars['Date']>;
  leadId?: InputMaybe<Scalars['PositiveInt']>;
  limit?: InputMaybe<Scalars['PositiveInt']>;
  offset?: InputMaybe<Scalars['NonNegativeInt']>;
  opportunityId?: InputMaybe<Scalars['Long']>;
  priority?: InputMaybe<ActivityPriority>;
  saleId?: InputMaybe<Scalars['Long']>;
  scheduleDate?: InputMaybe<Scalars['Date']>;
  source?: InputMaybe<ActivitySource>;
  status?: InputMaybe<ActivityStatus>;
  typeCode?: InputMaybe<Scalars['String']>;
};

export enum ActivityPriority {
  High = 'HIGH',
  Low = 'LOW',
  Normal = 'NORMAL'
}

export type ActivityResult = {
  __typename?: 'ActivityResult';
  active?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['PositiveInt']>;
  type?: Maybe<ActivityType>;
};

export enum ActivitySource {
  Lead = 'LEAD',
  Opportunity = 'OPPORTUNITY',
  Sale = 'SALE'
}

export enum ActivityStatus {
  Doing = 'DOING',
  Done = 'DONE',
  New = 'NEW'
}

export type ActivitySummary = {
  __typename?: 'ActivitySummary';
  date?: Maybe<Scalars['Date']>;
  hasTask?: Maybe<Scalars['Boolean']>;
};

export type ActivityType = {
  __typename?: 'ActivityType';
  active?: Maybe<Scalars['Boolean']>;
  applyTo?: Maybe<Array<Maybe<ActivityTypeApplyTo>>>;
  code?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export enum ActivityTypeApplyTo {
  Account = 'ACCOUNT',
  Merchant = 'MERCHANT',
  User = 'USER'
}

export type Addon = {
  __typename?: 'Addon';
  applyTo?: Maybe<Array<Maybe<AddonApplyTo>>>;
  code?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  effectiveDateEnd?: Maybe<Scalars['Date']>;
  effectiveDateStart?: Maybe<Scalars['Date']>;
  name?: Maybe<Scalars['String']>;
  parent?: Maybe<Addon>;
  type?: Maybe<AddonType>;
  values?: Maybe<Array<Maybe<AddonValue>>>;
};

export enum AddonApplyTo {
  Customer = 'CUSTOMER',
  Merchant = 'MERCHANT'
}

export type AddonFilter = {
  code?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['PositiveInt']>;
  name?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['NonNegativeInt']>;
  type?: InputMaybe<AddonType>;
};

export enum AddonType {
  Couple = 'COUPLE',
  Single = 'SINGLE'
}

export type AddonValue = {
  __typename?: 'AddonValue';
  active?: Maybe<Scalars['Boolean']>;
  addon?: Maybe<Addon>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['PositiveInt']>;
  type?: Maybe<AddonValueType>;
  value?: Maybe<Scalars['String']>;
};

export enum AddonValueType {
  Between = 'BETWEEN',
  Equal = 'EQUAL',
  GreaterThan = 'GREATER_THAN',
  LessThan = 'LESS_THAN'
}

export type Address = {
  __typename?: 'Address';
  address?: Maybe<Scalars['String']>;
  type?: Maybe<AddressType>;
  ward?: Maybe<Ward>;
};

export enum AddressType {
  Billing = 'BILLING',
  Primary = 'PRIMARY'
}

export enum BatchStatus {
  Abandoned = 'ABANDONED',
  Completed = 'COMPLETED',
  Failed = 'FAILED',
  Started = 'STARTED',
  Starting = 'STARTING',
  Stopped = 'STOPPED',
  Stopping = 'STOPPING',
  Unknown = 'UNKNOWN'
}

export type Campaign = {
  __typename?: 'Campaign';
  active?: Maybe<Scalars['Boolean']>;
  addonValues?: Maybe<Array<Maybe<AddonValue>>>;
  code?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  effectiveDateEnd?: Maybe<Scalars['Date']>;
  effectiveDateStart?: Maybe<Scalars['Date']>;
  form?: Maybe<Scalars['String']>;
  lastJobInstanceId?: Maybe<Scalars['Long']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type CampaignDataFilter = {
  campaignCode: Scalars['String'];
  limit?: InputMaybe<Scalars['PositiveInt']>;
  offset?: InputMaybe<Scalars['NonNegativeInt']>;
};

export type CampaignFilter = {
  active?: InputMaybe<Scalars['Boolean']>;
  code?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['PositiveInt']>;
  name?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['NonNegativeInt']>;
  type?: InputMaybe<Scalars['String']>;
};

export type CloseSalePayload = {
  address?: InputMaybe<CreateAddressPayload>;
  document?: InputMaybe<CreateDocumentPayload>;
  id: Scalars['Long'];
  orderedProductSkus: Array<InputMaybe<Scalars['String']>>;
};

export type Common = {
  __typename?: 'Common';
  active?: Maybe<Scalars['Boolean']>;
  code?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  orderNumber?: Maybe<Scalars['NonNegativeInt']>;
  value?: Maybe<Scalars['String']>;
};

export type CommonCategory = {
  __typename?: 'CommonCategory';
  active?: Maybe<Scalars['Boolean']>;
  code?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  values?: Maybe<Array<Maybe<Common>>>;
};

export type CommonCategoryFilter = {
  active?: InputMaybe<Scalars['Boolean']>;
  code?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['PositiveInt']>;
  name?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['NonNegativeInt']>;
};

export type CommonCategoryPayload = {
  active: Scalars['Boolean'];
  code: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
};

export type Contact = {
  __typename?: 'Contact';
  data?: Maybe<Scalars['String']>;
  type?: Maybe<ContactType>;
};

export enum ContactType {
  Email = 'EMAIL',
  Fax = 'FAX',
  Phone = 'PHONE'
}

export type ConvertLeadToOpportunityPayload = {
  leadId: Scalars['PositiveInt'];
  productSkus: Array<InputMaybe<Scalars['String']>>;
  salesmanUsername?: InputMaybe<Scalars['String']>;
};

export type Country = {
  __typename?: 'Country';
  code?: Maybe<Scalars['String']>;
  enName?: Maybe<Scalars['String']>;
  isoCode?: Maybe<Scalars['String']>;
  viName?: Maybe<Scalars['String']>;
};

export type CreateActivityPayload = {
  accountCode?: InputMaybe<Scalars['String']>;
  activityResultId?: InputMaybe<Scalars['PositiveInt']>;
  description?: InputMaybe<Scalars['String']>;
  leadId?: InputMaybe<Scalars['PositiveInt']>;
  opportunityId?: InputMaybe<Scalars['Long']>;
  priority: ActivityPriority;
  saleId?: InputMaybe<Scalars['Long']>;
  scheduleEnd?: InputMaybe<Scalars['DateTime']>;
  scheduleStart: Scalars['DateTime'];
  status: ActivityStatus;
  typeCode: Scalars['String'];
};

export type CreateAddonValuePayload = {
  active: Scalars['Boolean'];
  addonCode: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  type: AddonValueType;
  value: Scalars['String'];
};

export type CreateAddressPayload = {
  address: Scalars['String'];
  type?: InputMaybe<AddressType>;
  wardCode: Scalars['String'];
};

export type CreateCampaignPayload = {
  active: Scalars['Boolean'];
  code: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  effectiveDateEnd?: InputMaybe<Scalars['Date']>;
  effectiveDateStart: Scalars['Date'];
  form?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  type?: InputMaybe<Scalars['String']>;
};

export type CreateCommonPayload = {
  active?: InputMaybe<Scalars['Boolean']>;
  code: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  isDefault?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  orderNumber?: InputMaybe<Scalars['NonNegativeInt']>;
  value: Scalars['String'];
};

export type CreateContactPayload = {
  data: Scalars['String'];
  type: ContactType;
};

export type CreateDocumentPayload = {
  description?: InputMaybe<Scalars['String']>;
  documentCategoryCode: Scalars['String'];
  identityNumber: Scalars['String'];
  typeCode?: InputMaybe<Scalars['String']>;
  validFrom?: InputMaybe<Scalars['Date']>;
  validThrough?: InputMaybe<Scalars['Date']>;
};

export type CreateLeadPayload = {
  addressDetail?: InputMaybe<Scalars['String']>;
  companyName?: InputMaybe<Scalars['String']>;
  contactEmail?: InputMaybe<Scalars['String']>;
  contactPhone?: InputMaybe<Scalars['String']>;
  dob?: InputMaybe<Scalars['Date']>;
  firstname: Scalars['String'];
  gender: Gender;
  identityPaperData?: InputMaybe<Scalars['String']>;
  identityPaperId?: InputMaybe<Scalars['PositiveInt']>;
  interestedProductSkus?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  lastname: Scalars['String'];
  maritalStatusId?: InputMaybe<Scalars['PositiveInt']>;
  merchantId?: InputMaybe<Scalars['PositiveInt']>;
  occupationId?: InputMaybe<Scalars['PositiveInt']>;
  relationships?: InputMaybe<Array<InputMaybe<CreateRelationshipPayload>>>;
  saleUsername?: InputMaybe<Scalars['String']>;
  source: Scalars['String'];
};

export type CreateMerchantLevelPayload = {
  active?: InputMaybe<Scalars['Boolean']>;
  code: Scalars['String'];
  name: Scalars['String'];
};

export type CreateMerchantPayload = {
  active?: InputMaybe<Scalars['Boolean']>;
  address: CreateAddressPayload;
  businessRegistrationNumber: Scalars['String'];
  dob?: InputMaybe<Scalars['Date']>;
  domain?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  enName?: InputMaybe<Scalars['String']>;
  fax?: InputMaybe<Scalars['String']>;
  merchantLevelCode: Scalars['String'];
  merchantParentId?: InputMaybe<Scalars['PositiveInt']>;
  merchantTypeCode: Scalars['String'];
  numberOfEmployee?: InputMaybe<Scalars['NonNegativeInt']>;
  phone: Scalars['String'];
  relationships?: InputMaybe<Array<InputMaybe<CreateRelationshipPayload>>>;
  viName: Scalars['String'];
};

export type CreateOpportunityPayload = {
  accountCode: Scalars['String'];
  productSkus: Array<InputMaybe<Scalars['String']>>;
  salesmanUsername?: InputMaybe<Scalars['String']>;
};

export type CreateRelationshipPayload = {
  dob?: InputMaybe<Scalars['Date']>;
  email?: InputMaybe<Scalars['String']>;
  firstname: Scalars['String'];
  identityPaperId?: InputMaybe<Scalars['PositiveInt']>;
  identityPaperNumber?: InputMaybe<Scalars['String']>;
  lastname: Scalars['String'];
  phone?: InputMaybe<Scalars['String']>;
  type: Scalars['String'];
};

export type CreateUserPayload = {
  address?: InputMaybe<CreateAddressPayload>;
  dob: Scalars['Date'];
  document?: InputMaybe<CreateDocumentPayload>;
  email: Scalars['String'];
  firstname: Scalars['String'];
  gender: Gender;
  lastname: Scalars['String'];
  managerUsername?: InputMaybe<Scalars['String']>;
  maritalStatusId?: InputMaybe<Scalars['PositiveInt']>;
  merchantId: Scalars['PositiveInt'];
  phone?: InputMaybe<Scalars['String']>;
  relationships?: InputMaybe<Array<InputMaybe<CreateRelationshipPayload>>>;
  roleCodes?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  username: Scalars['String'];
};

export type Currency = {
  __typename?: 'Currency';
  code?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
};

export type District = {
  __typename?: 'District';
  code?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  province?: Maybe<Province>;
};

export type Document = {
  __typename?: 'Document';
  active?: Maybe<Scalars['Boolean']>;
  category?: Maybe<DocumentCategory>;
  description?: Maybe<Scalars['String']>;
  identityNumber?: Maybe<Scalars['String']>;
  identityPaper?: Maybe<IdentityPaper>;
  typeCode?: Maybe<Scalars['String']>;
  validFrom?: Maybe<Scalars['Date']>;
  validThrough?: Maybe<Scalars['Date']>;
};

export type DocumentCategory = {
  __typename?: 'DocumentCategory';
  active?: Maybe<Scalars['Boolean']>;
  code?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  detail?: Maybe<DocumentCategoryDetail>;
};

export type DocumentCategoryDetail = {
  __typename?: 'DocumentCategoryDetail';
  code?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type EnumDescription = {
  __typename?: 'EnumDescription';
  name?: Maybe<Scalars['String']>;
  values?: Maybe<Array<Maybe<EnumValue>>>;
};

export type EnumValue = {
  __typename?: 'EnumValue';
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export enum Gender {
  Female = 'FEMALE',
  Male = 'MALE',
  Other = 'OTHER'
}

export type IdentityPaper = {
  __typename?: 'IdentityPaper';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['PositiveInt']>;
  name?: Maybe<Scalars['String']>;
};

export type JobExecution = {
  __typename?: 'JobExecution';
  createTime?: Maybe<Scalars['DateTime']>;
  endTime?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['Long']>;
  lastUpdated?: Maybe<Scalars['DateTime']>;
  startTime?: Maybe<Scalars['DateTime']>;
  status?: Maybe<BatchStatus>;
  stepExecutions?: Maybe<Array<Maybe<StepExecution>>>;
};

export type JobInstance = {
  __typename?: 'JobInstance';
  instanceId?: Maybe<Scalars['Long']>;
  jobName?: Maybe<Scalars['String']>;
};

export type Lead = {
  __typename?: 'Lead';
  addressDetail?: Maybe<Scalars['String']>;
  assignments?: Maybe<Array<Maybe<LeadAssignment>>>;
  companyName?: Maybe<Scalars['String']>;
  contactEmail?: Maybe<Scalars['String']>;
  contactPhone?: Maybe<Scalars['String']>;
  dob?: Maybe<Scalars['Date']>;
  firstname?: Maybe<Scalars['String']>;
  gender?: Maybe<Gender>;
  id?: Maybe<Scalars['PositiveInt']>;
  identityPaper?: Maybe<IdentityPaper>;
  identityPaperData?: Maybe<Scalars['String']>;
  lastname?: Maybe<Scalars['String']>;
  maritalStatus?: Maybe<MaritalStatus>;
  merchant?: Maybe<Merchant>;
  occupation?: Maybe<Occupation>;
  products?: Maybe<Array<Maybe<Product>>>;
  refers?: Maybe<Array<Maybe<LeadReferrer>>>;
  relationships?: Maybe<Array<Maybe<Relationship>>>;
  source?: Maybe<Scalars['String']>;
  sourceDetail?: Maybe<Scalars['String']>;
  status?: Maybe<LeadStatus>;
};

export type LeadAssignment = {
  __typename?: 'LeadAssignment';
  assignedDate?: Maybe<Scalars['Date']>;
  salesman?: Maybe<User>;
};

export type LeadFilter = {
  email?: InputMaybe<Scalars['String']>;
  firstname?: InputMaybe<Scalars['String']>;
  lastname?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['PositiveInt']>;
  offset?: InputMaybe<Scalars['NonNegativeInt']>;
  phone?: InputMaybe<Scalars['String']>;
  search?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<Scalars['String']>;
};

export type LeadReferrer = {
  __typename?: 'LeadReferrer';
  referredDate?: Maybe<Scalars['Date']>;
  salesman?: Maybe<User>;
};

export enum LeadStatus {
  Converted = 'CONVERTED',
  New = 'NEW',
  Using = 'USING'
}

export type MaritalStatus = {
  __typename?: 'MaritalStatus';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['PositiveInt']>;
  name?: Maybe<Scalars['String']>;
};

export type Merchant = {
  __typename?: 'Merchant';
  active?: Maybe<Scalars['Boolean']>;
  addresses?: Maybe<Array<Maybe<Address>>>;
  businessRegistrationNumber?: Maybe<Scalars['String']>;
  contacts?: Maybe<Array<Maybe<Contact>>>;
  dob?: Maybe<Scalars['Date']>;
  domain?: Maybe<Scalars['String']>;
  enName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['PositiveInt']>;
  level?: Maybe<MerchantLevel>;
  numberOfEmployee?: Maybe<Scalars['NonNegativeInt']>;
  parent?: Maybe<Merchant>;
  relationships?: Maybe<Array<Maybe<Relationship>>>;
  roles?: Maybe<Array<Maybe<Role>>>;
  type?: Maybe<MerchantType>;
  viName?: Maybe<Scalars['String']>;
};

export type MerchantFilter = {
  active?: InputMaybe<Scalars['Boolean']>;
  domain?: InputMaybe<Scalars['String']>;
  enName?: InputMaybe<Scalars['String']>;
  levelCode?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  limit?: InputMaybe<Scalars['PositiveInt']>;
  offset?: InputMaybe<Scalars['NonNegativeInt']>;
  parentName?: InputMaybe<Scalars['String']>;
  productCode?: InputMaybe<Scalars['String']>;
  typeCode?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  viName?: InputMaybe<Scalars['String']>;
};

export type MerchantLevel = {
  __typename?: 'MerchantLevel';
  active?: Maybe<Scalars['Boolean']>;
  code?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type MerchantType = {
  __typename?: 'MerchantType';
  code?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addActivity?: Maybe<Activity>;
  addCampaign?: Maybe<Campaign>;
  addCommon?: Maybe<Common>;
  addCommonCategory?: Maybe<CommonCategory>;
  addLead?: Maybe<Lead>;
  addMerchant?: Maybe<Merchant>;
  addMerchantLevel?: Maybe<MerchantLevel>;
  addOpportunity?: Maybe<Opportunity>;
  addUserDevice?: Maybe<User>;
  assignLead?: Maybe<Lead>;
  assignOpportunity?: Maybe<Opportunity>;
  assignSale?: Maybe<Sale>;
  changeUserStatus?: Maybe<User>;
  closeSale?: Maybe<Sale>;
  completeActivity?: Maybe<Activity>;
  configCampaign?: Maybe<Campaign>;
  convertLeadToOpportunity?: Maybe<Opportunity>;
  convertOpportunityToSale?: Maybe<Scalars['Long']>;
  createUser?: Maybe<Scalars['String']>;
  deleteActivityById?: Maybe<Scalars['Int']>;
  deleteByUsername?: Maybe<Scalars['NonNegativeInt']>;
  deleteCommon?: Maybe<Scalars['NonNegativeInt']>;
  deleteCommonCategoryByCode?: Maybe<Scalars['NonNegativeInt']>;
  deleteMe?: Maybe<Scalars['NonNegativeInt']>;
  deleteMerchantById?: Maybe<Scalars['NonNegativeInt']>;
  deleteMerchantLevelByCode?: Maybe<Scalars['NonNegativeInt']>;
  dummy?: Maybe<Scalars['String']>;
  dummyCampaignData?: Maybe<Scalars['String']>;
  dummyLead?: Maybe<Array<Maybe<Scalars['PositiveInt']>>>;
  importLead?: Maybe<JobExecution>;
  referLead?: Maybe<Lead>;
  referOpportunity?: Maybe<Opportunity>;
  referSale?: Maybe<Sale>;
  removeUserDevice?: Maybe<User>;
  toggleCampaign?: Maybe<Campaign>;
  transferUser?: Maybe<User>;
  updateAccount?: Maybe<Account>;
  updateAccountAddress?: Maybe<Account>;
  updateAccountRelationships?: Maybe<Account>;
  updateActivity?: Maybe<Activity>;
  updateCampaign?: Maybe<Campaign>;
  updateCommon?: Maybe<Common>;
  updateCommonCategory?: Maybe<CommonCategory>;
  updateLead?: Maybe<Lead>;
  updateLeadAddress?: Maybe<Lead>;
  updateLeadRelationships?: Maybe<Lead>;
  updateMe?: Maybe<User>;
  updateMerchant?: Maybe<Merchant>;
  updateMerchantLevel?: Maybe<MerchantLevel>;
  updateMyPreferences?: Maybe<User>;
  updateOpportunity?: Maybe<Opportunity>;
  updateResource?: Maybe<Resource>;
  updateUser?: Maybe<User>;
};


export type MutationAddActivityArgs = {
  payload: CreateActivityPayload;
};


export type MutationAddCampaignArgs = {
  payload: CreateCampaignPayload;
};


export type MutationAddCommonArgs = {
  commonCategoryCode: Scalars['String'];
  payload: CreateCommonPayload;
};


export type MutationAddCommonCategoryArgs = {
  payload: CommonCategoryPayload;
};


export type MutationAddLeadArgs = {
  payload: CreateLeadPayload;
};


export type MutationAddMerchantArgs = {
  payload: CreateMerchantPayload;
};


export type MutationAddMerchantLevelArgs = {
  payload: CreateMerchantLevelPayload;
};


export type MutationAddOpportunityArgs = {
  payload: CreateOpportunityPayload;
};


export type MutationAddUserDeviceArgs = {
  deviceToken: Scalars['String'];
};


export type MutationAssignLeadArgs = {
  id: Scalars['PositiveInt'];
  salesmanUsername: Scalars['String'];
};


export type MutationAssignOpportunityArgs = {
  id: Scalars['Long'];
  salesmanUsername: Scalars['String'];
};


export type MutationAssignSaleArgs = {
  id: Scalars['Long'];
  salesmanUsername: Scalars['String'];
};


export type MutationChangeUserStatusArgs = {
  status: UserStatus;
  username: Scalars['String'];
};


export type MutationCloseSaleArgs = {
  payload: CloseSalePayload;
};


export type MutationCompleteActivityArgs = {
  id: Scalars['PositiveInt'];
};


export type MutationConfigCampaignArgs = {
  code: Scalars['String'];
  payloads: Array<InputMaybe<CreateAddonValuePayload>>;
};


export type MutationConvertLeadToOpportunityArgs = {
  payload: ConvertLeadToOpportunityPayload;
};


export type MutationConvertOpportunityToSaleArgs = {
  opportunityId: Scalars['Long'];
};


export type MutationCreateUserArgs = {
  payload: CreateUserPayload;
};


export type MutationDeleteActivityByIdArgs = {
  id: Scalars['PositiveInt'];
};


export type MutationDeleteByUsernameArgs = {
  username: Scalars['String'];
};


export type MutationDeleteCommonArgs = {
  code: Scalars['String'];
  commonCategoryCode: Scalars['String'];
};


export type MutationDeleteCommonCategoryByCodeArgs = {
  code: Scalars['String'];
};


export type MutationDeleteMerchantByIdArgs = {
  id: Scalars['PositiveInt'];
};


export type MutationDeleteMerchantLevelByCodeArgs = {
  code: Scalars['String'];
};


export type MutationDummyCampaignDataArgs = {
  campaignCode: Scalars['String'];
};


export type MutationImportLeadArgs = {
  fileKey: Scalars['String'];
  isWrite: Scalars['Boolean'];
};


export type MutationReferLeadArgs = {
  id: Scalars['PositiveInt'];
  referrerUsername: Scalars['String'];
};


export type MutationReferOpportunityArgs = {
  id: Scalars['Long'];
  referrerUsername: Scalars['String'];
};


export type MutationReferSaleArgs = {
  id: Scalars['Long'];
  referrerUsername: Scalars['String'];
};


export type MutationRemoveUserDeviceArgs = {
  deviceToken: Scalars['String'];
};


export type MutationToggleCampaignArgs = {
  active: Scalars['Boolean'];
  code: Scalars['String'];
};


export type MutationTransferUserArgs = {
  payload: TransferUserPayload;
};


export type MutationUpdateAccountArgs = {
  payload: UpdateAccountPayload;
};


export type MutationUpdateAccountAddressArgs = {
  payload: UpdateAccountAddressPayload;
};


export type MutationUpdateAccountRelationshipsArgs = {
  payload: UpdateAccountRelationshipsPayload;
};


export type MutationUpdateActivityArgs = {
  payload: UpdateActivityPayload;
};


export type MutationUpdateCampaignArgs = {
  payload: UpdateCampaignPayload;
};


export type MutationUpdateCommonArgs = {
  commonCategoryCode: Scalars['String'];
  payload: UpdateCommonPayload;
};


export type MutationUpdateCommonCategoryArgs = {
  payload: CommonCategoryPayload;
};


export type MutationUpdateLeadArgs = {
  payload: UpdateLeadPayload;
};


export type MutationUpdateLeadAddressArgs = {
  payload: UpdateLeadAddressPayload;
};


export type MutationUpdateLeadRelationshipsArgs = {
  payload: UpdateLeadRelationshipsPayload;
};


export type MutationUpdateMeArgs = {
  payload: UpdateMePayload;
};


export type MutationUpdateMerchantArgs = {
  payload: UpdateMerchantPayload;
};


export type MutationUpdateMerchantLevelArgs = {
  payload: UpdateMerchantLevelPayload;
};


export type MutationUpdateMyPreferencesArgs = {
  preferences: Scalars['Object'];
};


export type MutationUpdateOpportunityArgs = {
  payload: UpdateOpportunityPayload;
};


export type MutationUpdateResourceArgs = {
  payload: UpdateResourcePayload;
};


export type MutationUpdateUserArgs = {
  payload: UpdateUserPayload;
};

export type Notification = {
  __typename?: 'Notification';
  data?: Maybe<Scalars['Object']>;
  messageIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type Occupation = {
  __typename?: 'Occupation';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['PositiveInt']>;
  name?: Maybe<Scalars['String']>;
};

export type Opportunity = {
  __typename?: 'Opportunity';
  account?: Maybe<Account>;
  assignments?: Maybe<Array<Maybe<OpportunityAssignment>>>;
  createdBy?: Maybe<Scalars['String']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['Long']>;
  lead?: Maybe<Lead>;
  merchant?: Maybe<Merchant>;
  products?: Maybe<Array<Maybe<Product>>>;
  refers?: Maybe<Array<Maybe<OpportunityReferrer>>>;
  status?: Maybe<OpportunityStatus>;
};

export type OpportunityAssignment = {
  __typename?: 'OpportunityAssignment';
  assignedDate?: Maybe<Scalars['Date']>;
  salesman?: Maybe<User>;
};

export type OpportunityFilter = {
  createdDate?: InputMaybe<Scalars['Date']>;
  customerName?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['PositiveInt']>;
  merchantId?: InputMaybe<Scalars['PositiveInt']>;
  offset?: InputMaybe<Scalars['NonNegativeInt']>;
  productSku?: InputMaybe<Scalars['String']>;
  saleUsername?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<OpportunityStatus>;
};

export type OpportunityReferrer = {
  __typename?: 'OpportunityReferrer';
  referredDate?: Maybe<Scalars['Date']>;
  salesman?: Maybe<User>;
};

export enum OpportunityStatus {
  Canceled = 'CANCELED',
  Converted = 'CONVERTED',
  Using = 'USING'
}

export type PageAccount = {
  __typename?: 'PageAccount';
  count?: Maybe<Scalars['Int']>;
  list?: Maybe<Array<Maybe<Account>>>;
};

export type PageActivity = {
  __typename?: 'PageActivity';
  count?: Maybe<Scalars['NonNegativeInt']>;
  list?: Maybe<Array<Maybe<Activity>>>;
};

export type PageAddon = {
  __typename?: 'PageAddon';
  count?: Maybe<Scalars['NonNegativeInt']>;
  list?: Maybe<Array<Maybe<Addon>>>;
};

export type PageCampaign = {
  __typename?: 'PageCampaign';
  count?: Maybe<Scalars['NonNegativeInt']>;
  list?: Maybe<Array<Maybe<Campaign>>>;
};

export type PageCampaignData = {
  __typename?: 'PageCampaignData';
  count?: Maybe<Scalars['Long']>;
  list?: Maybe<Array<Maybe<Scalars['Object']>>>;
};

export type PageCommonCategory = {
  __typename?: 'PageCommonCategory';
  count?: Maybe<Scalars['NonNegativeInt']>;
  list?: Maybe<Array<Maybe<CommonCategory>>>;
};

export type PageLead = {
  __typename?: 'PageLead';
  count?: Maybe<Scalars['NonNegativeInt']>;
  list?: Maybe<Array<Maybe<Lead>>>;
};

export type PageMerchant = {
  __typename?: 'PageMerchant';
  count?: Maybe<Scalars['NonNegativeInt']>;
  list?: Maybe<Array<Maybe<Merchant>>>;
};

export type PageOpportunity = {
  __typename?: 'PageOpportunity';
  count?: Maybe<Scalars['NonNegativeInt']>;
  list?: Maybe<Array<Maybe<Opportunity>>>;
};

export type PageProduct = {
  __typename?: 'PageProduct';
  count?: Maybe<Scalars['NonNegativeInt']>;
  list?: Maybe<Array<Maybe<Product>>>;
};

export type PageResource = {
  __typename?: 'PageResource';
  count?: Maybe<Scalars['NonNegativeInt']>;
  list?: Maybe<Array<Maybe<Resource>>>;
};

export type PageSale = {
  __typename?: 'PageSale';
  count?: Maybe<Scalars['NonNegativeInt']>;
  list?: Maybe<Array<Maybe<Sale>>>;
};

export type PageUser = {
  __typename?: 'PageUser';
  count?: Maybe<Scalars['NonNegativeInt']>;
  list?: Maybe<Array<Maybe<User>>>;
};

export type Product = {
  __typename?: 'Product';
  name?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  sku?: Maybe<Scalars['String']>;
  stock?: Maybe<ProductStock>;
};

export type ProductAttribute = {
  __typename?: 'ProductAttribute';
  code?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  options?: Maybe<Array<Maybe<ProductAttributeOption>>>;
};

export type ProductAttributeOption = {
  __typename?: 'ProductAttributeOption';
  label?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type ProductFilter = {
  attribute?: InputMaybe<Scalars['Object']>;
  limit?: InputMaybe<Scalars['PositiveInt']>;
  name?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['NonNegativeInt']>;
  sku?: InputMaybe<Scalars['String']>;
};

export type ProductStock = {
  __typename?: 'ProductStock';
  isInStock?: Maybe<Scalars['Boolean']>;
  quantity?: Maybe<Scalars['NonNegativeInt']>;
};

export type Province = {
  __typename?: 'Province';
  code?: Maybe<Scalars['String']>;
  country?: Maybe<Country>;
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  accountByCode?: Maybe<Account>;
  activitiesByIds?: Maybe<Array<Maybe<Activity>>>;
  activityById?: Maybe<Activity>;
  activityDashboard?: Maybe<ActivityDashboard>;
  activityResults?: Maybe<Array<Maybe<ActivityResult>>>;
  activitySummary?: Maybe<Array<Maybe<ActivitySummary>>>;
  activityTypes?: Maybe<Array<Maybe<ActivityType>>>;
  addressById?: Maybe<Address>;
  campaignByCode?: Maybe<Campaign>;
  commonCategories?: Maybe<Array<Maybe<CommonCategory>>>;
  commonCategoriesByCodes?: Maybe<Array<Maybe<CommonCategory>>>;
  commonCategoryByCode?: Maybe<CommonCategory>;
  commons?: Maybe<Array<Maybe<Common>>>;
  countries?: Maybe<Array<Maybe<Country>>>;
  countryByCode?: Maybe<Country>;
  currencies?: Maybe<Array<Maybe<Currency>>>;
  currencyByCode?: Maybe<Currency>;
  districtByCode?: Maybe<District>;
  districtsByProvinceCode?: Maybe<Array<Maybe<District>>>;
  dummy?: Maybe<Scalars['String']>;
  enumDescriptionsByCodes?: Maybe<Array<Maybe<EnumDescription>>>;
  errorsFromImportLead?: Maybe<Array<Maybe<Lead>>>;
  exportAccounts?: Maybe<Scalars['String']>;
  exportLeads?: Maybe<Scalars['String']>;
  exportOpportunities?: Maybe<Scalars['String']>;
  identityPaperById?: Maybe<IdentityPaper>;
  identityPapers?: Maybe<Array<Maybe<IdentityPaper>>>;
  jobExecutionsByInstanceId?: Maybe<Array<Maybe<JobExecution>>>;
  lastJobInstance?: Maybe<JobInstance>;
  leadById?: Maybe<Lead>;
  maritalStatusById?: Maybe<MaritalStatus>;
  maritalStatuses?: Maybe<Array<Maybe<MaritalStatus>>>;
  me?: Maybe<User>;
  merchantById?: Maybe<Merchant>;
  merchantLevelByCode?: Maybe<MerchantLevel>;
  merchantLevels?: Maybe<Array<Maybe<MerchantLevel>>>;
  merchantTypeByCode?: Maybe<MerchantType>;
  merchantTypes?: Maybe<Array<Maybe<MerchantType>>>;
  merchantsByParentId?: Maybe<Array<Maybe<Merchant>>>;
  notificationsByMe?: Maybe<Array<Maybe<Notification>>>;
  occupationById?: Maybe<Occupation>;
  occupations?: Maybe<Array<Maybe<Occupation>>>;
  opportunityById?: Maybe<Opportunity>;
  pageAccount?: Maybe<PageAccount>;
  pageActivity?: Maybe<PageActivity>;
  pageAddon?: Maybe<PageAddon>;
  pageCampaign?: Maybe<PageCampaign>;
  pageCampaignData?: Maybe<PageCampaignData>;
  pageCommonCategory?: Maybe<PageCommonCategory>;
  pageLead?: Maybe<PageLead>;
  pageMerchant?: Maybe<PageMerchant>;
  pageOpportunity?: Maybe<PageOpportunity>;
  pageProduct?: Maybe<PageProduct>;
  pageResource?: Maybe<PageResource>;
  pageSale?: Maybe<PageSale>;
  pageUser?: Maybe<PageUser>;
  productAttributes?: Maybe<Array<Maybe<ProductAttribute>>>;
  productBySku?: Maybe<Product>;
  productsByMerchantId?: Maybe<Array<Maybe<Product>>>;
  provinceByCode?: Maybe<Province>;
  provincesByCountryCode?: Maybe<Array<Maybe<Province>>>;
  relationshipTypeByCode?: Maybe<RelationshipType>;
  relationshipTypes?: Maybe<Array<Maybe<RelationshipType>>>;
  resourceByCode?: Maybe<Resource>;
  roles?: Maybe<Array<Maybe<Role>>>;
  saleById?: Maybe<Sale>;
  saleDashboard?: Maybe<SaleDashboard>;
  templateImportLead?: Maybe<Scalars['String']>;
  userByUsername?: Maybe<User>;
  wardByCode?: Maybe<Ward>;
  wardsByDistrictCode?: Maybe<Array<Maybe<Ward>>>;
};


export type QueryAccountByCodeArgs = {
  code: Scalars['String'];
};


export type QueryActivitiesByIdsArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['PositiveInt']>>>;
};


export type QueryActivityByIdArgs = {
  id: Scalars['PositiveInt'];
};


export type QueryActivitySummaryArgs = {
  endDate: Scalars['Date'];
  startDate: Scalars['Date'];
};


export type QueryAddressByIdArgs = {
  id: Scalars['PositiveInt'];
};


export type QueryCampaignByCodeArgs = {
  code: Scalars['String'];
};


export type QueryCommonCategoriesByCodesArgs = {
  codes: Array<InputMaybe<Scalars['String']>>;
};


export type QueryCommonCategoryByCodeArgs = {
  code: Scalars['String'];
};


export type QueryCountryByCodeArgs = {
  code: Scalars['String'];
};


export type QueryCurrencyByCodeArgs = {
  code: Scalars['String'];
};


export type QueryDistrictByCodeArgs = {
  code: Scalars['String'];
};


export type QueryDistrictsByProvinceCodeArgs = {
  provinceCode: Scalars['String'];
};


export type QueryEnumDescriptionsByCodesArgs = {
  codes: Array<InputMaybe<Scalars['String']>>;
};


export type QueryErrorsFromImportLeadArgs = {
  fileKey: Scalars['String'];
};


export type QueryExportAccountsArgs = {
  filter: AccountFilter;
};


export type QueryExportLeadsArgs = {
  filter: LeadFilter;
};


export type QueryExportOpportunitiesArgs = {
  filter: OpportunityFilter;
};


export type QueryIdentityPaperByIdArgs = {
  id: Scalars['PositiveInt'];
};


export type QueryJobExecutionsByInstanceIdArgs = {
  instanceId: Scalars['Long'];
};


export type QueryLastJobInstanceArgs = {
  jobName: Scalars['String'];
};


export type QueryLeadByIdArgs = {
  id: Scalars['PositiveInt'];
};


export type QueryMaritalStatusByIdArgs = {
  id: Scalars['PositiveInt'];
};


export type QueryMerchantByIdArgs = {
  id: Scalars['PositiveInt'];
};


export type QueryMerchantLevelByCodeArgs = {
  code: Scalars['String'];
};


export type QueryMerchantTypeByCodeArgs = {
  code: Scalars['String'];
};


export type QueryMerchantsByParentIdArgs = {
  parentId?: InputMaybe<Scalars['PositiveInt']>;
};


export type QueryOccupationByIdArgs = {
  id: Scalars['PositiveInt'];
};


export type QueryOpportunityByIdArgs = {
  id: Scalars['Long'];
};


export type QueryPageAccountArgs = {
  filter: AccountFilter;
};


export type QueryPageActivityArgs = {
  filter?: InputMaybe<ActivityFilter>;
};


export type QueryPageAddonArgs = {
  filter: AddonFilter;
};


export type QueryPageCampaignArgs = {
  filter: CampaignFilter;
};


export type QueryPageCampaignDataArgs = {
  filter: CampaignDataFilter;
};


export type QueryPageCommonCategoryArgs = {
  filter: CommonCategoryFilter;
};


export type QueryPageLeadArgs = {
  filter: LeadFilter;
};


export type QueryPageMerchantArgs = {
  filter: MerchantFilter;
};


export type QueryPageOpportunityArgs = {
  filter: OpportunityFilter;
};


export type QueryPageProductArgs = {
  filter: ProductFilter;
};


export type QueryPageResourceArgs = {
  filter: ResourceFilter;
};


export type QueryPageSaleArgs = {
  filter: SaleFilter;
};


export type QueryPageUserArgs = {
  filter: UserFilter;
};


export type QueryProductBySkuArgs = {
  sku: Scalars['String'];
};


export type QueryProductsByMerchantIdArgs = {
  merchantId: Scalars['PositiveInt'];
};


export type QueryProvinceByCodeArgs = {
  code: Scalars['String'];
};


export type QueryProvincesByCountryCodeArgs = {
  countryCode: Scalars['String'];
};


export type QueryRelationshipTypeByCodeArgs = {
  code: Scalars['String'];
};


export type QueryResourceByCodeArgs = {
  code: Scalars['String'];
};


export type QuerySaleByIdArgs = {
  id: Scalars['Long'];
};


export type QueryUserByUsernameArgs = {
  username: Scalars['String'];
};


export type QueryWardByCodeArgs = {
  code: Scalars['String'];
};


export type QueryWardsByDistrictCodeArgs = {
  districtCode: Scalars['String'];
};

export type Relationship = {
  __typename?: 'Relationship';
  dob?: Maybe<Scalars['Date']>;
  email?: Maybe<Scalars['String']>;
  firstname?: Maybe<Scalars['String']>;
  identityPaper?: Maybe<IdentityPaper>;
  identityPaperNumber?: Maybe<Scalars['String']>;
  lastname?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  type?: Maybe<RelationshipType>;
};

export type RelationshipType = {
  __typename?: 'RelationshipType';
  applyTo?: Maybe<Array<Maybe<RelationshipTypeApplyTo>>>;
  code?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export enum RelationshipTypeApplyTo {
  Account = 'ACCOUNT',
  Lead = 'LEAD',
  Merchant = 'MERCHANT',
  User = 'USER'
}

export type Resource = {
  __typename?: 'Resource';
  active?: Maybe<Scalars['Boolean']>;
  code?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type ResourceFilter = {
  active?: InputMaybe<Scalars['Boolean']>;
  code?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['PositiveInt']>;
  name?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['NonNegativeInt']>;
};

export type Role = {
  __typename?: 'Role';
  active?: Maybe<Scalars['Boolean']>;
  code?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type Sale = {
  __typename?: 'Sale';
  account?: Maybe<Account>;
  assignments?: Maybe<Array<Maybe<SaleAssignment>>>;
  createDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['Long']>;
  lead?: Maybe<Lead>;
  merchant?: Maybe<Merchant>;
  opportunity?: Maybe<Opportunity>;
  orderId?: Maybe<Scalars['String']>;
  orderedProducts?: Maybe<Array<Maybe<Product>>>;
  products?: Maybe<Array<Maybe<Product>>>;
  refers?: Maybe<Array<Maybe<SaleReferrer>>>;
  salesman?: Maybe<Array<Maybe<User>>>;
  status?: Maybe<SaleStatus>;
};

export type SaleAssignment = {
  __typename?: 'SaleAssignment';
  assignedDate?: Maybe<Scalars['Date']>;
  salesman?: Maybe<User>;
};

export type SaleDashboard = {
  __typename?: 'SaleDashboard';
  numberOfConvertedSalesByDates?: Maybe<Scalars['Object']>;
  numberOfLeadsByDates?: Maybe<Scalars['Object']>;
  numberOfOpportunitiesByDates?: Maybe<Scalars['Object']>;
  numberOfSalesByDates?: Maybe<Scalars['Object']>;
};

export type SaleFilter = {
  customerName?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['PositiveInt']>;
  merchantId?: InputMaybe<Scalars['PositiveInt']>;
  offset?: InputMaybe<Scalars['NonNegativeInt']>;
  productSku?: InputMaybe<Scalars['String']>;
  salesmanUsername?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<SaleStatus>;
};

export type SaleReferrer = {
  __typename?: 'SaleReferrer';
  referredDate?: Maybe<Scalars['Date']>;
  salesman?: Maybe<User>;
};

export enum SaleStatus {
  Canceled = 'CANCELED',
  Converted = 'CONVERTED',
  Using = 'USING'
}

export type StepExecution = {
  __typename?: 'StepExecution';
  commitCount?: Maybe<Scalars['Long']>;
  id?: Maybe<Scalars['Long']>;
  processSkipCount?: Maybe<Scalars['Long']>;
  readCount?: Maybe<Scalars['Long']>;
  readSkipCount?: Maybe<Scalars['Long']>;
  rollbackCount?: Maybe<Scalars['Long']>;
  stepName?: Maybe<Scalars['String']>;
  writeCount?: Maybe<Scalars['Long']>;
  writeSkipCount?: Maybe<Scalars['Long']>;
};

export type TransferUserPayload = {
  merchantId: Scalars['PositiveInt'];
  roleCodes?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  usernameNew: Scalars['String'];
  usernameOld: Scalars['String'];
};

export type UpdateAccountAddressPayload = {
  accountCode: Scalars['String'];
  address?: InputMaybe<CreateAddressPayload>;
};

export type UpdateAccountPayload = {
  accountCode: Scalars['String'];
  dob: Scalars['Date'];
  email?: InputMaybe<Scalars['String']>;
  firstname: Scalars['String'];
  gender: Gender;
  lastname: Scalars['String'];
  merchantId?: InputMaybe<Scalars['PositiveInt']>;
  occupationId: Scalars['PositiveInt'];
};

export type UpdateAccountRelationshipsPayload = {
  accountCode: Scalars['String'];
  relationships?: InputMaybe<Array<InputMaybe<CreateRelationshipPayload>>>;
};

export type UpdateActivityPayload = {
  accountCode?: InputMaybe<Scalars['String']>;
  activityResultId?: InputMaybe<Scalars['PositiveInt']>;
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['PositiveInt'];
  leadId?: InputMaybe<Scalars['PositiveInt']>;
  priority: ActivityPriority;
  scheduleEnd?: InputMaybe<Scalars['DateTime']>;
  scheduleStart: Scalars['DateTime'];
  typeCode: Scalars['String'];
};

export type UpdateCampaignPayload = {
  active: Scalars['Boolean'];
  code: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  effectiveDateEnd?: InputMaybe<Scalars['Date']>;
  effectiveDateStart: Scalars['Date'];
  form?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  type?: InputMaybe<Scalars['String']>;
};

export type UpdateCommonPayload = {
  active?: InputMaybe<Scalars['Boolean']>;
  code: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  isDefault?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  orderNumber?: InputMaybe<Scalars['NonNegativeInt']>;
  value: Scalars['String'];
};

export type UpdateLeadAddressPayload = {
  addressDetail?: InputMaybe<Scalars['String']>;
  id: Scalars['PositiveInt'];
};

export type UpdateLeadPayload = {
  companyName?: InputMaybe<Scalars['String']>;
  contactEmail?: InputMaybe<Scalars['String']>;
  contactPhone?: InputMaybe<Scalars['String']>;
  dob?: InputMaybe<Scalars['Date']>;
  firstname: Scalars['String'];
  gender: Gender;
  id: Scalars['PositiveInt'];
  lastname: Scalars['String'];
  maritalStatusId?: InputMaybe<Scalars['PositiveInt']>;
  occupationId?: InputMaybe<Scalars['PositiveInt']>;
};

export type UpdateLeadRelationshipsPayload = {
  id: Scalars['PositiveInt'];
  relationships?: InputMaybe<Array<InputMaybe<CreateRelationshipPayload>>>;
};

export type UpdateMePayload = {
  address?: InputMaybe<CreateAddressPayload>;
  dob: Scalars['Date'];
  document?: InputMaybe<CreateDocumentPayload>;
  email: Scalars['String'];
  firstname: Scalars['String'];
  gender: Gender;
  lastname: Scalars['String'];
  phone?: InputMaybe<Scalars['String']>;
};

export type UpdateMerchantLevelPayload = {
  active?: InputMaybe<Scalars['Boolean']>;
  code: Scalars['String'];
  name: Scalars['String'];
};

export type UpdateMerchantPayload = {
  active?: InputMaybe<Scalars['Boolean']>;
  dob?: InputMaybe<Scalars['Date']>;
  domain?: InputMaybe<Scalars['String']>;
  enName?: InputMaybe<Scalars['String']>;
  id: Scalars['PositiveInt'];
  merchantLevelCode: Scalars['String'];
  merchantParentId?: InputMaybe<Scalars['PositiveInt']>;
  merchantTypeCode: Scalars['String'];
  numberOfEmployee?: InputMaybe<Scalars['NonNegativeInt']>;
  viName: Scalars['String'];
};

export type UpdateOpportunityPayload = {
  id: Scalars['Long'];
  productSkus: Array<InputMaybe<Scalars['String']>>;
};

export type UpdateResourcePayload = {
  active: Scalars['Boolean'];
  code: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  parentCode?: InputMaybe<Scalars['String']>;
};

export type UpdateUserPayload = {
  address?: InputMaybe<CreateAddressPayload>;
  dob: Scalars['Date'];
  document?: InputMaybe<CreateDocumentPayload>;
  email: Scalars['String'];
  firstname: Scalars['String'];
  gender: Gender;
  lastname: Scalars['String'];
  managerUsername?: InputMaybe<Scalars['String']>;
  maritalStatusId?: InputMaybe<Scalars['PositiveInt']>;
  merchantId: Scalars['PositiveInt'];
  phone?: InputMaybe<Scalars['String']>;
  relationships?: InputMaybe<Array<InputMaybe<CreateRelationshipPayload>>>;
  roleCodes?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  username: Scalars['String'];
};

export type User = {
  __typename?: 'User';
  address?: Maybe<Address>;
  addresses?: Maybe<Array<Maybe<Address>>>;
  contacts?: Maybe<Array<Maybe<Contact>>>;
  createdBy?: Maybe<Scalars['String']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  dob?: Maybe<Scalars['Date']>;
  documents?: Maybe<Array<Maybe<Document>>>;
  firstname?: Maybe<Scalars['String']>;
  gender?: Maybe<Gender>;
  lastname?: Maybe<Scalars['String']>;
  manager?: Maybe<User>;
  maritalStatus?: Maybe<MaritalStatus>;
  merchant?: Maybe<Merchant>;
  preferences?: Maybe<Scalars['Object']>;
  relationships?: Maybe<Array<Maybe<Relationship>>>;
  resources?: Maybe<Array<Maybe<Resource>>>;
  roles?: Maybe<Array<Maybe<Role>>>;
  status?: Maybe<UserStatus>;
  username?: Maybe<Scalars['String']>;
};

export type UserFilter = {
  firstname?: InputMaybe<Scalars['String']>;
  lastname?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['PositiveInt']>;
  merchantId?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['NonNegativeInt']>;
  roles?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  status?: InputMaybe<UserStatus>;
  username?: InputMaybe<Scalars['String']>;
};

export enum UserStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Locked = 'LOCKED'
}

export type Ward = {
  __typename?: 'Ward';
  code?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  district?: Maybe<District>;
  name?: Maybe<Scalars['String']>;
};
