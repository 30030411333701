<div class="p-ng">
  <div [ngClass]="{ 'p-input-filled': !border, field: true }">
    <label class="block">
      <span *ngIf="showLabel"> {{ label | translate }}</span>
      <span *ngIf="checkRequire()" class="p-errors" style="color: red; margin-left: 3px">*</span></label
    >
    <p-treeSelect
      (onShow)="onTreePanelShow()"
      [filter]="true"
      [formControl]="control"
      [metaKeySelection]="metaKeySelection"
      [options]="nodes"
      [selectionMode]="'multiple'"
      placeholder="Select Item"
    ></p-treeSelect>

    <div class="relative b-errors">
      <ng-container *ngIf="errors">
        <small class="p-error error-msg block fadeinup animation-duration-300">
          <span class="block">{{ getError().key | translate: getError().required }}</span>
        </small>
      </ng-container>
    </div>
  </div>
</div>
