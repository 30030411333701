import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-lp-popup-common',
  templateUrl: './lp-popup-common.component.html',
  styleUrls: ['./lp-popup-common.component.scss'],
})
export class LpPopupCommonComponent implements OnInit {
  @Input() show = false;
  @Input() headerText = '';
  @Input() isNotAllowOutSideValid: any;
  @Input() widthContent = 80; // Chiều rộng modal
  @Input() heightContent = 75; // Chiều cao modal
  @Input() unit = '%'; // Đơn vị px hoặc %
  /**
   * modalType: Loại modal dùng cho việc search hay nhập thông tin
   * Nếu modalType = 'SEARCH' => z-index để 100
   * Nếu modalType = 'INFO' => z-index để 10
   */
  @Input() modalType = 'SEARCH';
  @Output() showChange = new EventEmitter<boolean>();
  @Output() hideModal = new EventEmitter();
  //constructor() {}

  ngOnInit(): void {
    console.log(this.show);
  }

  hide(): void {
    this.show = false;
    this.hideModal.emit();
  }
}
