import { Observable } from 'rxjs';
import { AfterContentInit, Component, Injector, OnDestroy, Type, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Column, DataTable } from '@cores/models/data-table.model';
import * as _ from 'lodash';
import { Paginator } from 'primeng/paginator';
import { BaseComponent } from '@shared/components/base.component';
import { Action } from '@cores/utils/enums';
import { PaginatorModel } from '@cores/models/paginator.model';

@Component({
  template: ` <ng-content></ng-content>`,
  providers: [],
})
export class BaseTableComponent extends BaseComponent implements OnDestroy, AfterContentInit {
  today = new Date();
  dataTable: DataTable = {
    content: [],
    size: 10,
    totalElements: 0,
    totalPages: 0,
    first: 0,
  };
  isChangeParam = true;

  columns: Column[] = [];
  prevParams: any;
  params: Type<any> | FormGroup | undefined;
  @ViewChild('paginator', { static: true }) paginator: Paginator | undefined;

  constructor(override injector: Injector) {
    super(injector);
    this.initConfigAction();
  }

  ngAfterContentInit() {
    this.loadParams();
    if (this.params instanceof FormGroup) {
      this.params?.valueChanges.subscribe((data: any) => {
        const prevParams = _.cloneDeep(this.prevParams);
        delete prevParams?.limit;
        delete prevParams?.offset;
        this.isChangeParam = _.differenceWith(_.toPairs(data), _.toPairs(prevParams), _.isEqual).length > 0;
      });
    }
  }

  override init() {
    super.init();
  }

  // getState() {
  //   this.serviceBase.getState().subscribe({
  //     next: (state) => {
  //       this.propData = cloneDeep(state);
  //       this.stateData = cloneDeep(state);
  //       this.mapState();
  //       this.search();
  //     },
  //   });
  // }

  initConfigAction() {
    console.debug('initConfigAction');
  }

  mapState() {
    console.debug('mapState');
  }

  search(firstPage?: boolean) {
    const params = this.mapDataSearch(!!firstPage);
    // cal api tìm kiếm
  }

  pageChange(paginator: PaginatorModel) {
    this.dataTable.size = paginator.rows;
    this.dataTable.first = paginator.first;
    this.isChangeParam = true;
    this.search(false);
  }

  mapDataSearch(firstPage: boolean) {
    if (firstPage) {
      this.dataTable.first = 0;
    } else {
      if (this.params instanceof FormGroup) {
        this.params.patchValue(this.prevParams, { emitEvent: false });
      } else {
        this.params = _.cloneDeep(this.prevParams);
      }
    }
    return {
      offset: this.dataTable.first,
      limit: this.dataTable.size,
      ...(this.params instanceof FormGroup ? this.params.getRawValue() : this.params),
    };
  }

  saveParams() {
    sessionStorage.clear();
    sessionStorage.setItem(
      this.router.url,
      JSON.stringify({
        ...(this.params instanceof FormGroup ? this.params.getRawValue() : this.params),
        limit: this.dataTable.size,
        offset: this.dataTable.first,
      })
    );
  }

  loadParams() {
    if (sessionStorage.getItem(this.router.url)) {
      const params = JSON.parse(sessionStorage.getItem(this.router.url) || '');
      if (this.params instanceof FormGroup) {
        this.params.patchValue(params);
      } else {
        this.params = params;
      }
      this.dataTable.first = params?.['offset'];
      this.dataTable.size = params?.['limit'];
    }
  }

  initDialogData(): Observable<any> {
    return new Observable(observable => {
      observable.next();
    });
  }

  onCreate() {
    this.messageService.confirm('message.actionNeedsToBeCompleted');
  }

  onUpdate(item: any) {
    this.messageService.confirm('message.actionNeedsToBeCompleted');
  }

  onDelete(item: any) {
    this.messageService.confirm('message.actionNeedsToBeCompleted');
  }

  onActive(item: any) {
    this.messageService.confirm('message.actionNeedsToBeCompleted');
  }

  onActionRow(action: any) {
    if (action.type === Action.Update) {
      this.onUpdate(action.item);
    } else if (action.type === Action.Delete) {
      this.onDelete(action.item);
    } else if (action.type === Action.Active) {
      this.onActive(action.item);
    }
  }

  //Dùng để override param search theo nghiệp vụ nếu có
  reload() {
    this.search(true);
    this.saveParams();
  }

  exportExcel() {
    this.messageService.confirm('message.actionNeedsToBeCompleted');
  }
}
